import React, { useEffect, useRef } from 'react';


export const ModalComponent = ({ isOpen, onClose, children,styles,paymentModal=false }) => {
    const modalRef = useRef();
  
    // Close the modal if clicked outside of it
    useEffect(() => {
      if(!paymentModal)
  {   
     const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          onClose();
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };}
    }, [onClose]);
  
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm" >
        <div ref={modalRef} className={`bg-primary ${styles} rounded-lg shadow-lg w-[650px]`}>
          {children}
        </div>
      </div>
    );
  };
  


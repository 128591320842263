import React, { useEffect, useState, useCallback } from "react";
import { DonationCard } from "../../components/DonationCard";
import { GetAppText } from "../../../utils";
import { useSelector, useDispatch } from "react-redux";
import { ButtonComponent } from "../../components/ButtonComponent";
import { useLocation, useNavigate } from "react-router-dom";
import Dropdown from "../../components/Dropdown";

import SvgComponent from "../../components/SvgComponent";
import { SeparatorComponent } from "../../components/Separator";
import { getRequest } from "../../../apis/methods";
import { apiRoutes } from "../../../apis/routes";
import { Loader } from "../../components/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { modalHandler } from "../../../store/slices/modalSlice";
import DonationModal from "../../components/DonationModal";
import { handleClick, handleSuccess } from "../../components/Toast/toastHelper";

import debounce from "lodash.debounce";
import Pagination from "../../components/Pagination";
const DonationProjects = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen4, setIsDropdownOpen4] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);
  const [isAddModal, setIsAddModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedValue2, setSelectedValue2] = useState("");
  const [selectedValueCondition, setSelectedValueCondition] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [selectedGovernet, setSelectedGovernet] = useState({
    id: 1,
    name: "The capital",
    name_ar: "\u0627\ufedf\ufecc\ufe8e\ufebb\ufee4\ufe94",
    is_active: true,
  });
  const [data, setData] = useState("");
  const [details, setDetails] = useState("");
  const [category, setCategory] = useState("");
  const [isButton, setIsButton] = useState(false);
  const [type, setType] = useState("");
  const [sort, setSort] = useState("");
  const [searchParam, setSearchParam] = useState("");
  const [categoryParams, setCategoryParams] = useState([]);
  const [statusParam, setStatusParam] = useState([]);
  // const [statusParam, setStatusParam] = useState("");
  const [sortParam, setSortParam] = useState("");
  const [typeSelect, setTypeSelect] = useState("Projects");
  const navigate = useNavigate();
  const [selectedAreaParam, setSelectedAreaParam] = useState([]);
  const [loadingStates, setLoadingStates] = useState({});
  const language = useSelector((state) => state.language.value);
  const isOpen = useSelector((state) => state.openModel.value);
  const info = useSelector((state) => state.info.value);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState("");
  const [areas, setAreas] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  // const lastPage = 5;
  // Example last page number
  // Debounce the fetchData function

  const fetchData = async () => {
    setLoading(true);
  
    let data = {};
    if (searchParam) {
      data["search"] = searchParam;
    }
    if (typeSelect === "Projects" && categoryParams.length > 0) {
      data["category_ids"] = categoryParams;
    }
    if (typeSelect === "Projects" && statusParam.length > 0) {
      data["status"] = statusParam;
    }
    if (typeSelect === "Mosques" && selectedAreaParam.length > 0) {
      data["area_ids"] = selectedAreaParam;
    }
    if (typeSelect === "Projects" && sortParam) {
      data["sort_by"] = sortParam;
    }
    if (currentPage) {
      data["page"] = currentPage;
    }
    
    try {
      const response = await getRequest(
        `${
          typeSelect === "Projects"
            ? apiRoutes.get_projects
            : apiRoutes.get_mosques
        }`,
        data
      );
      if (response.status === "success") {
        setData(response?.data?.data);
        setCategory(response?.data?.categories);
        setType(response?.data?.types);
        setSort(response?.data?.sortBy);
  
        setLastPage(response?.data?.last_page);
        // Set other data fields as needed
        console.log("Results", response);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  

  const debouncedFetchData = useCallback(
    debounce(fetchData, 300), // Delay of 300ms
    [
      searchParam,
      categoryParams,
      statusParam,
      sortParam,
      typeSelect,
      currentPage,
      selectedAreaParam,
    ]
  );
  
  // Initial fetch without delay
  useEffect(() => {
    fetchData(); // Instant fetch on initial load
    setInitialLoad(false);
  }, []);
  
  // Debounced fetch for parameter changes
  useEffect(() => {
    if (!initialLoad) {
      debouncedFetchData();
    }
    return debouncedFetchData.cancel; // Cleanup on unmount
  }, [
    searchParam,
    categoryParams,
    statusParam,
    sortParam,
    typeSelect,
    currentPage,
    selectedAreaParam,
  ]);
  

  // Call the debounced function whenever the search param or other parameters change
  // useEffect(() => {
  //   debouncedFetchData();
  //   return debouncedFetchData.cancel; // Cleanup on unmount
  // }, [
  //   searchParam,
  //   categoryParams,
  //   statusParam,
  //   sortParam,
  //   debouncedFetchData,
  //   currentPage,
  //   selectedAreaParam,
  // ]);

  useEffect(() => {
    if (isOpen?.type === "toast") {
      handleSuccess(isOpen?.msg, language);
    } else if (isOpen?.type === "toastError") {
      handleClick(isOpen?.msg, language);
    } else if (isOpen === "priceError") {
      handleClick("Price is required", language);
    }
  }, [isOpen]);
  const location = useLocation();
  //   const fetchData = async () => {
  //     setLoading(true)
  //     try {
  //       const response = await getRequest(`${apiRoutes.get_projects}/search?search=${searchParam}&category_id=${categoryParams}&status=${statusParam}&sort_by=${sortParam}`
  //         ); // Replace with your endpoint
  // if(response.success)
  //    {
  //     setLoading(false)
  //     setData(response?.data?.projects)
  //     setCategory(response?.data?.categories)
  //     setType(response?.data?.types)
  //     setSort(response?.data?.sortBy)
  //     console.log("Results",response)
  //   }
  //   else{
  //     setLoading(false)
  //   }
  //     } catch (error) {
  //       setLoading(false)
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  useEffect(() => {
    getArea();
  }, [selectedGovernet]);

  const getArea = async () => {
    let data = {};
    if (selectedGovernet) {
      data["governorate_id "] = selectedGovernet?.id;
    }
    try {
      const response = await getRequest(`${apiRoutes.get_areas}`, data); // Replace with your endpoint

      if (response.status === "success") {
        console.log("ResponseInfo", response);
        setAreas(response?.data);
        // dispatch(infoHandler(response?.data));
      }
    } catch (error) {
      console.error("Error fetching device info:", error);
    }
  };

  const handler = () => {
    console.log("helooo");
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handler4 = () => {
    console.log("helooo");
    setIsDropdownOpen4(!isDropdownOpen4);
  };
  const handler2 = () => {
    console.log("helooo");
    setIsDropdownOpen2(!isDropdownOpen2);
  };
  const handler3 = () => {
    console.log("helooo");
    setIsDropdownOpen3(!isDropdownOpen);
  };

  const getQueryParams = () => new URLSearchParams(location.search);
  const handleProjectClick = (val) => {
    setDetails(val);

    setIsAddModal(true);
    dispatch(modalHandler("isDonate"));
  };
  const updateQueryParams = (newParams) => {
    const params = getQueryParams();
    Object.keys(newParams).forEach((key) => {
      params.set(key, newParams[key]);
    });
    return params.toString();
  };

  const handleSelect = (value) => {
    // const newParams = updateQueryParams({ type: value });
    // navigate(`?${newParams}`);
    console.log("VALUE", value);
    setStatusParam(value?.value);
    if (language === "_ar") {
      setSelectedValue(value?.key_ar);
    } else {
      setSelectedValue(value?.key);
    }
    setIsDropdownOpen(false);
  };
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedAreaValues, setSelectedAreaValues] = useState([]);
  const [selectedStatusValues, setSelectedStatusValues] = useState([]);

  // const handleSelectCondition = (value) => {
  //   setIsButton(true)
  //   console.log("VALUE",value)
  //   setCategoryParams(value?.id)
  //   if(language === "_ar"){
  //     setSelectedValueCondition(value?.title_ar)
  //   }
  //   else
  //  { setSelectedValueCondition(value?.title)}
  //   setIsDropdownOpen2(false);
  // };
  const handleSelectCondition = (value) => {
    // Update selectedValues with selected/unselected options
    setSelectedValues((prevSelected) => {
      const isSelected = prevSelected.some((item) => item.id === value.id);

      // Update selected IDs array
      setCategoryParams((prevIds) => {
        if (isSelected) {
          // Remove unselected option's ID from categoryParams array
          return prevIds.filter((id) => id !== value.id);
        } else {
          // Add selected option's ID to categoryParams array
          return [...prevIds, value.id];
        }
      });

      // Return updated selected values
      if (isSelected) {
        // Unselect the option
        return prevSelected.filter((item) => item.id !== value.id);
      } else {
        // Select the option
        return [...prevSelected, value];
      }
    });

    console.log("Selected IDs:", categoryParams);
  };

  const handleSelectArea = (value) => {
    // Update selectedValues with selected/unselected options
    setSelectedAreaValues((prevSelected) => {
      const isSelected = prevSelected.some((item) => item.id === value.id);

      // Update selected IDs array
      setSelectedAreaParam((prevIds) => {
        if (isSelected) {
          // Remove unselected option's ID from categoryParams array
          return prevIds.filter((id) => id !== value.id);
        } else {
          // Add selected option's ID to categoryParams array
          return [...prevIds, value.id];
        }
      });

      // Return updated selected values
      if (isSelected) {
        // Unselect the option
        return prevSelected.filter((item) => item.id !== value.id);
      } else {
        // Select the option
        return [...prevSelected, value];
      }
    });

    console.log("Selected IDs:", categoryParams);
  };

  const handleSelectStatus = (value) => {
    // Update selectedValues with selected/unselected options
    console.log("Values", value);
    setSelectedStatusValues((prevSelected) => {
      const isSelected = prevSelected.some((item) => item.id === value.id);

      // Update selected IDs array
      setStatusParam((prevIds) => {
        if (isSelected) {
          // Remove unselected option's ID from categoryParams array
          return prevIds.filter((id) => id !== value.id);
        } else {
          // Add selected option's ID to categoryParams array
          return [...prevIds, value.id];
        }
      });

      // Return updated selected values
      if (isSelected) {
        // Unselect the option
        return prevSelected.filter((item) => item.id !== value.id);
      } else {
        // Select the option
        return [...prevSelected, value];
      }
    });

    console.log("Selected IDs:", statusParam);
  };
  const handleSelectGovernet = (value) => {
    // Update selectedValues with selected/unselected options
    setSelectedGovernet(value);
    setSelectedAreaParam([]);
    console.log("Values", value);
    // setIsDropdownOpen4(false)
  };

  const handleSelect2 = (value) => {
    console.log("setSortParam", value?.type);
    setSortParam(value?.type);
    if (language === "_ar") {
      setSelectedValue2(value?.name_ar);
    } else {
      setSelectedValue2(value?.name);
    }

    setIsDropdownOpen3(false);
  };

  const handleCartDetails = (items) => {
    console.log("Data", items);
    setDetails(items);
    setIsAddModal(true);
    dispatch(modalHandler(""));
  };

  const handleSelectType = (item) => {
    if (item === "Projects") {
      setTypeSelect("Projects");
    } else {
      setTypeSelect("Mosques");
    }
  };

  const ImageClick = (val) => {
    console.log("VAL", val);
    if (typeSelect === "Projects") {
      navigate(`/donation-project/${val?.slug}`);
    } else {
      navigate(`/donation-mosque/${val?.slug}`);
    }
  };

  const handlePageChange = (page) => {
    console.log("Selected page:", page); // Log the clicked page number
    setCurrentPage(page);
  };

  return (
    <>
      <div
        className={`flex ${
          language === "_ar" ? "flex-row-reverse " : ""
        } md:px-20 px-5 md:py-16 py-8 w-full`}
      >
        <div className="space-y-6 w-full">
          <ToastContainer />
          <h1
            className={`flex ${
              language === "_ar" ? "flex-row-reverse " : ""
            } font-bold text-F24`}
          >
            {GetAppText("donationProjects")}
          </h1>
          {/*Page header*/}
          <div
            className={`sm:flex items-center ${
              language === "_ar" ? "sm:flex-row-reverse space-x-reverse" : ""
            } space-x-4 md:space-y-0 space-y-6 w-full`}
          >
            <div className="lg:w-4/12  w-full">
              {/* <label
                htmlFor="default-search"
                className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
              >
                Search
              </label> */}
              <div className="relative">
                <div
                  className={`absolute inset-y-0 flex items-center pointer-events-none 
                    ${language === "_ar" ? " end-0  pe-3" : "start-0 ps-3"}`}
                >
                  <SvgComponent
                    name={"Search"}
                    className={`w-4 h-4`}
                    stroke={"#727272"}
                    fill={"none"}
                  />
                </div>
                <input
                  type="text"
                  value={searchParam}
                  onChange={(e) => setSearchParam(e.target.value)}
                  id="default-search"
                  className={`block w-full p-2 py-3 placeholder:text-F12 text-sm placeholder:text-lightTextColor focus:outline-none focus:border-borderColor focus:ring-0 border-2 border-borderColor rounded-lg ${
                    language === "_ar" ? "pe-8 text-right" : "ps-8"
                  }`}
                  placeholder={GetAppText("search_here")}
                />
              </div>
            </div>

            <div
              className="bg-borderColor md:block hidden h-10"
              style={{ width: "1.5px" }}
            ></div>
            <div
              className={`flex   sm:flex-wrap gap-3   md:items-end items-start ${
                language === "_ar"
                  ? `${typeSelect === "Mosques" && "flex-row-reverse"}`
                  : `${typeSelect === "Projects" && "lg:flex-row-reverse"}`
              }  justify-between w-full`}
            >
              {typeSelect === "Projects" && (
                <div
                  className={`md:flex  lg:space-y-0 md:space-y-1 items-center ${
                    language === "_ar"
                      ? "flex-row-reverse md:space-x-reverse"
                      : "flex-row"
                  }    md:gap-3 `}
                >
                  <div className="text-F16 text-primary md:whitespace-nowrap md:block hidden">
                    {GetAppText("sort")}
                  </div>
                  <div>
                    <ButtonComponent
                      onClick={handler3}
                      title={
                        selectedValue2 === ""
                          ? GetAppText("closest_complete")
                          : selectedValue2
                      }
                      svg={
                        <SvgComponent
                          name={"ChevronDown"}
                          className={`w-2.5 h-2`}
                          stroke={"#1B1C1E"}
                          fill={"none"}
                        />
                      }
                      styles={
                        "py-2 sm:px-4 px-1 sm:text-F16 text-F14 whitespace-nowrap overflow-hidden text-ellipsis"
                      }
                      type="secondary_transparent"
                    />
                    <Dropdown
                      mainStyle={`!w-40 !h-36`}
                      sort={true}
                      handleSelect={handleSelect2}
                      isOpen={isDropdownOpen3}
                      options={info?.sorts}
                      setIsDropdownOpen={setIsDropdownOpen3}
                    />
                  </div>
                </div>
              )}
               {/* <div className="sm:hidden flex flex-wrap gap-3 ">
                <ButtonComponent
                  onClick={() => handleSelectType("Mosques")}
                  title={GetAppText("masjid")}
                  svg={
                    <SvgComponent
                      name={
                        typeSelect === "Mosques"
                          ? "CheckboxFill"
                          : "CircleCheckboxUnfill"
                      }
                      className={`w-6 h-6`}
                      fill={"none"}
                    />
                  }
                  styles={
                    "py-2 sm:px-4 px-1 sm:text-F16 text-F14 whitespace-nowrap flex  flex-row-reverse gap-2 "
                  }
                  type="secondary_transparent"
                />
                <ButtonComponent
                  onClick={() => handleSelectType("Projects")}
                  title={GetAppText("projects")}
                  svg={
                    <SvgComponent
                      name={
                        typeSelect === "Projects"
                          ? "CheckboxFill"
                          : "CircleCheckboxUnfill"
                      }
                      className={`w-6 h-6`}
                      fill={"none"}
                    />
                  }
                  styles={
                    "py-2 sm:px-4 px-1 sm:text-F16 text-F14 whitespace-nowrap flex  flex-row-reverse gap-2 "
                  }
                  type="secondary_transparent"
                />
                </div> */}
              <div
                className={`flex   flex-wrap   gap-3  ${
                  language === "_ar" ? "justify-end" : ""
                }    `}
              >
                {typeSelect === "Projects" ? (
                  <div>
                    <ButtonComponent
                      onClick={handler}
                      title={
                        selectedValue === ""
                          ? GetAppText("donation_type")
                          : selectedValue
                      }
                      svg={
                        <SvgComponent
                          name={"ChevronDown"}
                          className={`w-2.5 h-2`}
                          stroke={"#1B1C1E"}
                          fill={"none"}
                        />
                      }
                      styles={"py-2 sm:px-4 px-1 sm:text-F16 text-F14 whitespace-nowrap    "}
                      type="secondary_transparent"
                    />
                    <Dropdown
                      // sort={true}
                      category={true}
                      mainStyle={"!h-56 !w-48"}
                      handleSelect={handleSelectStatus}
                      isOpen={isDropdownOpen}
                      options={info?.tags}
                      selectedValues={selectedStatusValues}
                      setIsDropdownOpen={setIsDropdownOpen}
                    />
                  </div>
                ) : (
                  <div>
                    <ButtonComponent
                      onClick={handler}
                      title={
                        selectedGovernet === ""
                          ? GetAppText("donation_type")
                          : `${
                              language == "_ar"
                                ? selectedGovernet?.name_ar
                                : selectedGovernet?.name
                            }`
                      }
                      svg={
                        <SvgComponent
                          name={"ChevronDown"}
                          className={`w-2.5 h-2`}
                          stroke={"#1B1C1E"}
                          fill={"none"}
                        />
                      }
                      styles={"py-2 px-4 whitespace-nowrap   text-F16 "}
                      type="secondary_transparent"
                    />
                    <Dropdown
                      governet={true}
                      // category={true}
                      mainStyle={"!h-56 !w-48"}
                      handleSelect={handleSelectGovernet}
                      isOpen={isDropdownOpen}
                      options={info?.governorates}
                      selectedValues={selectedGovernet}
                      setIsDropdownOpen={setIsDropdownOpen}
                    />
                  </div>
                )}
                {isButton && (
                  <ButtonComponent
                    onClickSvg={() => {
                      setIsButton(false);
                      setSelectedValueCondition("");
                      setCategoryParams("");
                    }}
                    title={selectedValueCondition}
                    svg={
                      <SvgComponent
                        name={"Cross"}
                        className={`w-2 h-2`}
                        stroke={"white"}
                        fill={"none"}
                      />
                    }
                    styles={"py-2 px-4 whitespace-nowrap   text-F16"}
                    type="primary"
                  />
                )}
                {typeSelect === "Projects" ? (
                  <div className="">
                    <ButtonComponent
                      onClick={handler2}
                      title={
                        selectedValueCondition === ""
                          ? GetAppText("condition")
                          : selectedValueCondition
                      }
                      svg={
                        <SvgComponent
                          name={"ChevronDown"}
                          className={`w-2.5 h-2`}
                          stroke={"#1B1C1E"}
                          fill={"none"}
                        />
                      }
                      styles={"py-2 sm:px-4 px-1 sm:text-F16 text-F14 whitespace-nowrap   "}
                      type="secondary_transparent"
                    />
                    <Dropdown
                      category={true}
                      mainStyle={"!h-56 !w-48"}
                      handleSelect={handleSelectCondition}
                      isOpen={isDropdownOpen2}
                      options={info?.categories}
                      selectedValues={selectedValues}
                      setIsDropdownOpen={setIsDropdownOpen2}
                    />
                  </div>
                ) : (
                  <div>
                    <ButtonComponent
                      onClick={handler2}
                      title={
                    
                           GetAppText("area")
                         
                      }
                      svg={
                        <SvgComponent
                          name={"ChevronDown"}
                          className={`w-2.5 h-2`}
                          stroke={"#1B1C1E"}
                          fill={"none"}
                        />
                      }
                      styles={"py-2 px-4 whitespace-nowrap  text-F16 "}
                      type="secondary_transparent"
                    />
                    <Dropdown
                      area={true}
                      mainStyle={"!h-56 !w-48"}
                      handleSelect={handleSelectArea}
                      isOpen={isDropdownOpen2}
                      options={areas}
                      selectedValues={selectedAreaValues}
                      setIsDropdownOpen={setIsDropdownOpen2}
                    />
                  </div>
                )}
                <div
                  className="bg-borderColor lg:block hidden  h-10"
                  style={{ width: "1.5px" }}
                ></div>
                {/* <div className="flex   md:hidden ">
                <div
                  className={`flex   md:hidden  space-y-1 items-center ${
                    language === "_ar"
                      ? "flex-row-reverse space-x-reverse"
                      : "flex-row"
                  }    gap-3 `}
                >
                  <div className="text-F16 text-primary whitespace-nowrap">
                    {GetAppText("sort")}
                  </div>
                  <div>
                    <ButtonComponent
                      onClick={handler3}
                      title={
                        selectedValue2 === ""
                          ? GetAppText("closest_complete")
                          : selectedValue2
                      }
                      svg={
                        <SvgComponent
                          name={"ChevronDown"}
                          className={`w-2.5 h-2`}
                          stroke={"#1B1C1E"}
                          fill={"none"}
                        />
                      }
                      styles={
                        "py-2 px-4 text-F16 whitespace-nowrap overflow-hidden text-ellipsis"
                      }
                      type="secondary_transparent"
                    />
              <Dropdown
                    mainStyle={`!w-48 !h-36`}
                    sort={true}
                    handleSelect={handleSelect2}
                    isOpen={isDropdownOpen3}
                    options={info?.sorts}
                    setIsDropdownOpen={setIsDropdownOpen3}
                    />
                  </div>
                </div>
                </div> */}
                <div className="sm:flex hidden  flex-wrap gap-3 ">
                <ButtonComponent
                  onClick={() => handleSelectType("Mosques")}
                  title={GetAppText("masjid")}
                  svg={
                    <SvgComponent
                      name={
                        typeSelect === "Mosques"
                          ? "CheckboxFill"
                          : "CircleCheckboxUnfill"
                      }
                      className={`w-6 h-6`}
                      fill={"none"}
                    />
                  }
                  styles={
                    "py-2 sm:px-4 px-1 sm:text-F16 text-F14 whitespace-nowrap flex  flex-row-reverse gap-2 "
                  }
                  type="secondary_transparent"
                />
                <ButtonComponent
                  onClick={() => handleSelectType("Projects")}
                  title={GetAppText("projects")}
                  svg={
                    <SvgComponent
                      name={
                        typeSelect === "Projects"
                          ? "CheckboxFill"
                          : "CircleCheckboxUnfill"
                      }
                      className={`w-6 h-6`}
                      fill={"none"}
                    />
                  }
                  styles={
                    "py-2 sm:px-4 px-1 sm:text-F16 text-F14 whitespace-nowrap flex  flex-row-reverse gap-2 "
                  }
                  type="secondary_transparent"
                />
                </div>
              </div>
            </div>
            <div className={`sm:hidden flex w-full ${language==="_ar"?"justify-end":""}  gap-3 `}>
                <ButtonComponent
                  onClick={() => handleSelectType("Mosques")}
                  title={GetAppText("masjid")}
                  svg={
                    <SvgComponent
                      name={
                        typeSelect === "Mosques"
                          ? "CheckboxFill"
                          : "CircleCheckboxUnfill"
                      }
                      className={`w-6 h-6`}
                      fill={"none"}
                    />
                  }
                  styles={
                    "py-2 sm:px-4 px-1 sm:text-F16 text-F14 whitespace-nowrap flex  flex-row-reverse gap-2 "
                  }
                  type="secondary_transparent"
                />
                <ButtonComponent
                  onClick={() => handleSelectType("Projects")}
                  title={GetAppText("projects")}
                  svg={
                    <SvgComponent
                      name={
                        typeSelect === "Projects"
                          ? "CheckboxFill"
                          : "CircleCheckboxUnfill"
                      }
                      className={`w-6 h-6`}
                      fill={"none"}
                    />
                  }
                  styles={
                    "py-2 sm:px-4 px-1 sm:text-F16 text-F14 whitespace-nowrap flex  flex-row-reverse gap-2 "
                  }
                  type="secondary_transparent"
                />
                </div>
          </div>
          <SeparatorComponent className={"!my-8"} />
          <div
            className={`flex flex-wrap md:justify-normal  justify-center md:gap-6 gap-4 ${
              language === "_ar"
                ? "flex-row-reverse space-x-reverse"
                : "flex-row"
            } w-full`}
          >
            {loading ? (
              <div className="flex h-[440px] w-full justify-center items-center">
                <Loader />
              </div>
            ) : data?.length > 0 ? (
              data.map((item, index) => (
                <DonationCard
                  onImageClick={() => {
                    ImageClick(item);
                  }}
                  onCartClick={() => {
                    handleCartDetails(item);
                  }}
                  cardLoader={loadingStates[item.id]}
                  onClick={() => {
                    handleProjectClick(item);
                  }}
                  key={index}
                  donateType={typeSelect}
                  data={item}
                />
              ))
            ) : (
              !loading && (
                <div className="flex w-full h-[350px] justify-center text-F20 font-semibold items-center">
                  {GetAppText("no_data")}
                </div>
              )
            )}
          </div>
          <div className="sm:flex hidden justify-center w-full pt-4">
            <Pagination
              currentPage={currentPage}
              lastPage={lastPage}
              onPageChange={handlePageChange}
            />
          </div>
          <div className="sm:hidden flex justify-center font-medium text-F18 w-full  pt-2">
          <ButtonComponent disabled={currentPage===lastPage} onClick={()=>{setCurrentPage(currentPage+1)}} styles={"!w-full !py-2.5  !border-2 !text-lightTextColor !border-borderColor"}  title={GetAppText("show_more")} type="transparent"/>
         
          </div>
        </div>
      </div>
      {isAddModal && (
        <DonationModal
          isAddModal={isAddModal}
          details={details}
          setIsAddModal={setIsAddModal}
        />
      )}
    </>
  );
};

export default DonationProjects;

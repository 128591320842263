import React from "react";
import { AppRoutes } from "../../router";
import Header from "../components/header";
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../context/authContext";
import { Loader } from "../components/Loader";
export const AppLayout = () => {
  const location = useLocation();
  const { loading } = useAuth();
  const hideLayout = location.pathname === "/404"; // Check if the route is 404
  // Render a loader while the authentication status is being checked
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div><Loader/></div> {/* You can replace this with a spinner */}
      </div>
    );
  }

  return (
    <div className="justify-center w-full h-full font-cairo">
        {!hideLayout && (
  <div className={`md:h-[135px]  h-[90px] ${location.pathname === "/" ? "absolute" : ""}`}>
        <Header />
      </div>)}
      <div className="w-full">
        <div className="h-full w-full bg-bodyColor">
          <AppRoutes />
        </div>
        <div>
        {!hideLayout &&    <Footer />}
        </div>
      </div>
    </div>
  );
};

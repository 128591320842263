import React from "react";

// Import all SVGs as React components
import * as Icons from "../../../assets/svgs"; // Adjust the path to where your SVGs are located

const SvgComponent = ({ name, className, stroke, fill }) => {
  const IconComponent = Icons[name];

  if (!IconComponent) {
    console.error(`SVG not found: ${name}`);
    return null;
  }

  return <IconComponent className={className} stroke={stroke} fill={fill} />;
};

export default SvgComponent;

import React, { useEffect, useRef, useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import { GetAppText, ValidationErrors } from "../../../utils";

import { ButtonComponent } from "../../components/ButtonComponent";

import {ReactComponent as Texture} from "../../../assets/svgs/texture3.svg"
import { modalHandler } from "../../../store/slices/modalSlice";
import SvgComponent from "../../components/SvgComponent";
import Dropdown from "../../components/Dropdown";
import { apiRoutes } from "../../../apis/routes";
import { getRequest, postRequest } from "../../../apis/methods";
import { dataHandler } from "../../../store/slices/dataSlice";
import { AlertComponent } from "../../components/AlertComponent";
// import countryData from '../../../assets/countryData.json';
export const Login = () => {
  const language = useSelector((state) => state.language.value);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('+973');
  const [selectedValue2, setSelectedValue2] = useState(1);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState("");
  // const [countryData, setCountryData] = useState("");
  const[digitLenght,setDigitLenght]=useState(8)
  const countryData = useSelector((state) => state.country.value);
  // useEffect(()=>{
  //   fetchData()
  // },[])
  
  //   const fetchData = async () => {
  //     // setLoading(true)
  //     try {
  //       const response = await getRequest(apiRoutes.countries); // Replace with your endpoint
  // if(response.success)
  //    { 
  //     // setLoading(false)
  //     setCountryData(response?.data?.countries)
  //     console.log("Results",response)
  //   }
  //   else{
  //     // setLoading(false)
  //   }
  //     } catch (error) {
  //       setLoading(false)
  //       console.error('Error fetching data:', error);
  //     }
  //   };


  const dispatch = useDispatch();
  const handleSignup=()=>{
    // navigate(`/signup`);
    dispatch(modalHandler("SignupModal"));
}

const handler=()=>{
  console.log("helooo")
  setIsDropdownOpen(!isDropdownOpen);
}
const inputRef = useRef(null);

useEffect(() => {
  inputRef.current.focus();
  console.log("countryData",countryData)
}, []);

const handleSubmit = async() => {
  const formattedPhone = Number(selectedValue.replace('+', ''));
  console.log("formattedPhone",formattedPhone)
  const number=Number(phoneNumber)
  const combinedPhone = `${formattedPhone}${number}`; 


  const data={
    country_id:selectedValue2,
    phone: Number(combinedPhone),
    // phone_number: phoneNumber,

  }
  setLoading(true)
  if(selectedValue && phoneNumber)
  {try {
    const response = await postRequest(apiRoutes.login,data); // Replace with your endpoint
  if(response.status==="success")
  { 
  setLoading(false)
  
  // setData(response?.data)
  console.log("Results",response)
  dispatch(dataHandler(data));
  dispatch(modalHandler("OtpModal"));

  }
  else{
    
    let error = '';
    if (
      response?.data?.message.includes(ValidationErrors.phoneNumber.notComplete)
    ) {
      error = GetAppText('phone_not_valid');
    } else if (
      response?.data?.message.includes(ValidationErrors.phoneNumber.notExists)
    ) {
      error = GetAppText('phone_not_associated');
    } else {
      error = GetAppText('something_went_wrong');
    }
    setAlert(error)
    setTimeout(()=>{
      setAlert("")
    },[2000])
  setLoading(false)
  }
  } catch (error) {
    setLoading(false)
    console.error('Error fetching data:', error);
  }
  }
  else{
    setLoading(false)
    setAlert("Phone number is required")
    setTimeout(()=>{
      setAlert("")
    },[2000])
  }
      console.log("Final Payload:", data);
      // Here, you can send the payload to an API or perform any other action needed.
    };
  

const handleSelect = (value) => {
  setDigitLenght(value?.phone_digits)
  setSelectedValue(value?.phone_code);
  setSelectedValue2(value?.id)
  setPhoneNumber('')
  setIsDropdownOpen(false);
  inputRef.current.focus();
};

const handleInputChange = (e) => {
  const value = e.target.value;

  // Allow only numbers and limit to 8 digits
  const numericValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
  if (numericValue.length <= digitLenght) {
    setPhoneNumber(numericValue);
  }
};

const handleKeyDown = (event) => {
  if (["e", "E", ".", "-", "+"].includes(event.key)) {
    event.preventDefault();
  }
  if (event.key === 'Enter') {
    handleSubmit(); // Call the handleSubmit function when Enter key is pressed
  }
};

  return (
 <>
      <div
        className={`bg-primary relative w-full rounded-lg p-6  ${
          language === "_ar" ? "text-right" : "text-left"
        }`}
      >
         <div className="absolute z-50 top-0 left-0">
     <Texture className="object-cover"/>
      </div>
      <div className="space-y-4">
        <div className={`text-white text-center font-bold text-F24`}>
          {GetAppText("login")}
        </div>
        <div className=" w-full">
          <div className="flex flex-col space-y-4">
            <div className="flex flex-col space-y-2 relative mb-14">
              <h1 className="text-white text-F14">{GetAppText("phone")}</h1>
              {/* <InputComponent /> */}
              <div>
          <div className="flex items-center  py-1 w-full bg-white border-2 border-borderColor absolute z-50 rounded-lg ">
            <div onClick={handler} className="flex cursor-pointer items-center px-2.5 space-x-2 border-e border-e-borderColor">
              <div className="text-sm">
                {selectedValue}
              </div>
            <SvgComponent
                      name={"ChevronDown"}
                      className={`w-2.5 h-1.5`}
                      stroke={"black"}
                      fill={"none"}
                    />
            </div>
                          <input
                           onKeyDown={handleKeyDown}
                    type={"number"}
                    id={"phone"}
                    value={phoneNumber}
                    onChange={handleInputChange}
                    className={`block no-spinner w-full border-none focus:border-borderColor focus:outline-none focus:ring-0  placeholder:text-F14 text-sm placeholder:text-lightTextColor ${
                      language === "_ar" ? "pe-2 text-right" : "ps-3"
                    } `}
                   
                    name={"phone"}
                    ref={inputRef}
                  />
                        
                  </div>
                  {isDropdownOpen&& <Dropdown phone={true} mainStyle={`w-full mt-14 !h-[200px]`}  handleSelect={handleSelect}  isOpen={isDropdownOpen} options={countryData} setIsDropdownOpen={setIsDropdownOpen} />}
                
                  </div>
            </div>
            {/* <div className="flex flex-col space-y-2">
              <h1 className="text-white text-F14">{GetAppText("password")}</h1>
              <InputComponent 
              name={"password"}
              type={isPasswordVisible ? "text" : "password"}
              toggleVisibility={
                () => setIsPasswordVisible(!isPasswordVisible)
               
              }
              isPasswordVisible={ isPasswordVisible 
              }
              
              />
            </div> */}
          </div>
          {/* <div className={`pt-4 flex  ${language === "_ar" ?"flex-row-reverse":"" } `} onClick={()=>{ dispatch(modalHandler("ResetModal"))}}>
            <h1 className="text-white w-fit text-F11  hover:underline cursor-pointer">
              {GetAppText("forgotPassword")}
            </h1>
          </div> */}
          {alert&&<AlertComponent text={alert} isArb={false} svgName={"Warning"} svgColor={"#E94747"} styles={"!text-errorColor !bg-alertErrorBg !mt-5"}/>}
          <div className="pt-4 w-full"> 
            <div className="space-y-4 w-full">
              <ButtonComponent disabled={loading}  loading={loading} styles={"!w-full !py-2.5"} onClick={handleSubmit} title={GetAppText("login")} />
              <ButtonComponent styles={"!w-full !py-2.5"} onClick={handleSignup} title={GetAppText("createNewAccount")} type="transparent"/>
            </div>
          </div>
        </div>
        </div>
      </div>

</>
  );
};

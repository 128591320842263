import React, { useEffect, useState,useCallback } from "react";
import { NavLink } from "react-router-dom";
import { GetAppText } from "../../utils";
import { ReactComponent as SunniLogo } from "../../assets/svgs/sunniLogo.svg";
import { ReactComponent as ShoppingCart } from "../../assets/svgs/shoppingCart.svg";
import { ReactComponent as UserIcon } from "../../assets/svgs/userIcon.svg";
import { useSelector, useDispatch } from "react-redux";
import { switchLanguage } from "../../store/slices/languageSlice";
import {useLocation,useNavigate } from 'react-router-dom';
import SvgComponent from "./SvgComponent";
import { ModalComponent } from "./Modal";
import { Login } from "../pages/Login";
import { Otp } from "../pages/Otp";
import Signup from "../pages/Signup";
import { modalHandler } from "../../store/slices/modalSlice";
import { ResetPassword } from "../pages/ResetPassword";
import { getRequest } from "../../apis/methods";
import { apiRoutes } from "../../apis/routes";
import Dropdown from "./Dropdown";
import debounce from "lodash.debounce";
function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const [langText, setLangText] = useState("EN");
  const [menuOpen, setMenuOpen] = useState(false);
  const [userToken, setUserToken] = useState("");
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);
  const isOpen = useSelector((state) => state.openModel.value);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isloginModal, setIsloginModal] = useState(false);
  const [isSignupnModal, setIsSignupModal] = useState(false);
  const [isOtpModal, setIsOtpModal] = useState(false);
  const [isResetModal, setIsResetModal] = useState(false);
  const[searchParam,setSearchParam]=useState('')
  const [showInput, setShowInput] = useState(false);
  const language = useSelector((state) => state.language.value);
  const data = useSelector((state) => state.userDetails.value);
  useEffect(() => {
    setLangText(language === "_en" ? "ع" : "EN");
  }, [language]);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [selectedValue2, setSelectedValue2] = useState('');
  const [selectedTab, setSelectedTab] = useState('');


  // Function to handle changes to localStorage across different tabs/windows
  const handleStorageChange = (event) => {
    if (event.key === "token") { // Check for changes on the specific key
      console.log("Storage changed!", event.newValue);
      setUserToken(event.newValue); // Update state if localStorage value changes
    }
     // Handle the case where localStorage.clear() was called (event.key will be null)
     if (event.key === null) {
      console.log("Storage cleared!");
      setUserToken(""); // Clear the token state when localStorage is cleared
    }
  };
useEffect(() => {
  // Load data from localStorage when component mounts
  const storedData = getLocalStorageData("token");
  if (storedData) {

    setUserToken(storedData);
  }
  else{
    setUserToken("")
  }

  // Add event listener for `storage` event
  window.addEventListener("storage", handleStorageChange);

  // Clean up event listener on component unmount
  return () => {
    window.removeEventListener("storage", handleStorageChange);
  };
}, [localStorage.getItem("token")]);

const getLocalStorageData = (key) => {
  const storedValue = localStorage.getItem(key); // Retrieve from localStorage

  // console.log("Token",storedValue)
  return storedValue;
};
  useEffect(()=>{
    if(isOpen=="LoginModal")
  {  setIsloginModal(true)
    setIsSignupModal(false)
    setIsOtpModal(false)
  }
    else if(isOpen=="SignupModal"){
      setIsloginModal(false)
      setIsSignupModal(true)
      setIsOtpModal(false)
    }
    else if(isOpen=="OtpModal"){
      setIsloginModal(false)
      setIsSignupModal(false)
      setIsOtpModal(true)
    }
    else if(isOpen=="ResetModal"){
      setIsloginModal(false)
      setIsSignupModal(false)
      setIsOtpModal(false)
      setIsResetModal(true)
    }
    else if(isOpen==""){
      setIsloginModal(false)
      setIsSignupModal(false)
      setIsOtpModal(false)
      setIsResetModal(false)
    }
  
  },
  [isOpen])
const[loading,setLoading]=useState(false)
const[Data,setData]=useState('')

  // Scroll to top on route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]); // Scrolls to top whenever location (route) changes

  useEffect(() => {
    setLangText(language === "_en" ? "ع" : "EN");
  }, [language]);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handler2=()=>{
    console.log("helooo")
    setIsDropdownOpen2(!isDropdownOpen2);
  }

  const handleSelect2 = (value) => {
    console.log("VALUE",value)
    if(language==='_ar'){
setSearchParam(value?.title_ar)
    }
    else{
      setSearchParam(value?.title)
    }
  if(value?.type==='PROJECT'){
    navigate(`/donation-project/${value?.slug}`);}
    else if (value?.type==='MOSQUE'){
      navigate(`/donation-mosque/${value?.slug}`)
    }
    else if (value?.type==='NEWS'){
      navigate(`/news/${value?.slug}`)
    }
    else if (value?.type==='STORY'){
      navigate(`/story/${value?.slug}`)
    }
    setIsDropdownOpen2(false);
    
  };
  
  const handleCloseInput = () => {
    
  
    setShowInput(false); // Close input and show "Donate Now" button when cross is clicked
  //  setTimeout(()=>{
  //   setPrice('')
  //  },150)
    
  };
  const debouncedFetchData = useCallback(
    debounce(async () => {
      setLoading(true);

      try {
        const response = await getRequest(`${apiRoutes.get_search}?search=${searchParam}`); // Replace with your endpoint
  if(response.status==="success")
     { 
      setLoading(false)
      setData(response?.data)
      console.log("Results",response)
    }
    else{
      setLoading(false)
    }
      } catch (error) {
        setLoading(false)
        console.error('Error fetching data:', error);
      }
      
      finally {
        setLoading(false);
      }
    }, 300), // Delay of 300ms
    [searchParam]
  );

  // Call the debounced function whenever the search param or other parameters change
  useEffect(() => {
    debouncedFetchData();
    return debouncedFetchData.cancel; // Cleanup on unmount
  }, [searchParam, debouncedFetchData]);



  const dispatch = useDispatch();
  const handleToggleLanguage = () => {
    // setSidebarOpen(false);
    dispatch(switchLanguage(language === "_en" ? "_ar" : "_en"));
  };
  return (
<nav className={`w-full md:h-[135px]  h-[90px] pt-1 fixed ${location.pathname === "/" && !isScrolled ? "bg-[#FFFFFF40]" : "bg-white"} z-50`}>
  <div className={`w-full lg:px-14 px-7  flex  ${language === "_ar" ? "flex-row-reverse " : ""} items-center justify-between py-2.5`}>
    <div className={`flex  ${language === "_ar" ? "flex-row-reverse " : ""} items-center`}>
    {/* Logo */}
    <NavLink to={"/"}>
    <div className="cursor-pointer ">
      <div className="justify-center md:h-full md:w-full h-[60px] w-[60px]  flex items-center">
        <SunniLogo />
      </div>
      <h1 className={`lg:text-F11 xl:text-F12 lg:block hidden text-F10 text-center max-w-full line-clamp-1  ${location.pathname === "/"  && !isScrolled? "text-white" : "text-black"} font-semibold`}>
        {GetAppText("official_donation_portal")}
      </h1>
    </div>
</NavLink>
    {/* Mobile Menu Button */}
    

    {/* Menu */}
    {/* Menu */}
    <div className={` ${language === "_ar" ? "pr-7" : ""}  md:block hidden`}>
      <div className="w-full  md:w-auto" id="navbar-default">
        <ul
          className={`font-semibold flex flex-col xl:text-F18 lg:text-F15 md:text-F14 text-F13 p-4 md:p-0 mt-4 md:mt-0 ${
            language === "_ar" ? "md:flex-row-reverse md:space-x-reverse" : "md:flex-row"
          }`}
        >
          <li className={`${language === "_ar" ? "md:border-l-[2px] text-right" : "md:border-r-[2px]"}  border-borderColor px-4`}>
            <NavLink
              to={"/"}
              className={({ isActive }) =>
                isActive
                  ? `${location.pathname === "/" && !isScrolled? "text-secondary" : "text-secondary"}`
                  : `${location.pathname === "/" && !isScrolled? "text-white" : "text-black"}`
              }
            >
              {GetAppText("home")}
            </NavLink>
          </li>
          <li className={`${language === "_ar" ? "md:border-l-[2px] text-right " : "md:border-r-[2px]"} border-borderColor px-4`}>
            <NavLink
              to={"/donation-projects"}
              className={({ isActive }) =>
                isActive
                  ? `${location.pathname === "/"&& !isScrolled ? "text-white" : "text-secondary"}`
                  : `${location.pathname === "/" && !isScrolled ? "text-white" : "text-black"}`
              }
            >
              {GetAppText("donationProjects")}
            </NavLink>
          </li>
          <li className={`${language === "_ar" ? "md:border-l-[2px] text-right " : "md:border-r-[2px]"} border-borderColor px-4`}>
            <NavLink
              to={"/about-us"}
              className={({ isActive }) =>
                isActive
                  ? `${location.pathname === "/" && !isScrolled? "text-white" : "text-secondary"}`
                  : `${location.pathname === "/" && !isScrolled ? "text-white" : "text-black"}`
              }
            >
              {GetAppText("about_us")}
            </NavLink>
          </li>
   
      
          <li className={`px-4 ${language === "_ar" ? 'text-right':''}`}>
            <NavLink
              to={"/technical-support"}
              className={({ isActive }) =>
                isActive
                  ? `${location.pathname === "/"&& !isScrolled ? "text-white" : "text-secondary"}`
                  : `${location.pathname === "/"&& !isScrolled ? "text-white" : "text-black"}`
              }
            >
              {GetAppText("technical_support")}
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
    </div>
    {/* Options */}
    <div className="flex  md:w-fit w-full items-center justify-center">
      <div className={`md:flex  hidden ${language === "_ar" ? "flex-row-reverse md:space-x-reverse" : "flex-row"} md:space-x-4 md:space-y-0 space-y-3  w-full`}>
        <div className="lg:w-[240px] xl:w-[270px] md:w-[190px] w-11/12 relative">
          <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only">
            Search
          </label>
          <div className="relative" onClick={()=>{setIsDropdownOpen2(true)}}>
            <div className={`absolute inset-y-0 space-x-3 flex items-center pointer-events-none ${language === "_ar" ? " end-0  pe-3" : "start-0 ps-3"}`}>
            <SvgComponent
                    name={"Search"}
                    className={`w-4 h-4`}
                    stroke={"#727272"}
                    fill={"none"}
                  />
            </div>
            <input
              type="text"
              id="default-search"
              value={searchParam}  onChange={(e) => setSearchParam(e.target.value)}
              className={`block w-full p-2 py-2.5  placeholder:text-F12 text-sm  placeholder:text-lightTextColor focus:outline-none focus:border-borderColor focus:ring-0 border-2 border-borderColor rounded-lg ${
                language === "_ar" ? "pe-8 text-right" : "ps-8"
              }`}
              placeholder={GetAppText("search_here")}
            />
          </div>
          <Dropdown global={true} mainStyle={"!max-h-52 !w-72 !text-F14  !font-bold"}  handleSelect={handleSelect2} 
             isOpen={isDropdownOpen2} 
             options={Data} 
             selectedValues={selectedValue2}
             setIsDropdownOpen={setIsDropdownOpen2} />
        </div>

        <div className={`  flex relative  ${language === "_ar" ? "md:flex-row-reverse md:space-x-reverse" : "flex-row"} items-center space-x-3`}>
          <div
              title={GetAppText("language")}
            className={`  items-center  w-10 h-10  justify-center flex ${location.pathname === "/" && !isScrolled ? "text-white border-white hover:bg-gray-300" : "text-black border-black hover:bg-gray-200"} border-[2px] rounded-md cursor-pointer`}
            onClick={() => handleToggleLanguage()}
          >
            <span className={`${language === "_ar" ? "" : "-mt-1"} text-F15 font-semibold`}>{langText}</span>
          </div>
          <NavLink to={"/donars-bag"}>
            <div
                 title={GetAppText("donation_basket")}
            className={`w-10 h-10 items-center ${location.pathname === "/"&& !isScrolled ? "text-white border-white hover:bg-gray-300" : "text-black border-black hover:bg-gray-200"} justify-center flex border-[2px] cursor-pointer rounded-md`}>
              <SvgComponent
                name={"Cart"}
                className={`w-6 h-5`}
                stroke={`${location.pathname === "/"&& !isScrolled ? "white " : "black"}`}
                fill={"none"}
              />
            </div>
          </NavLink>
         {userToken? 
         <NavLink to={"/profile"}>
            <div
             title={GetAppText("account_settings")}
            className="w-10 h-10 items-center justify-center cursor-pointer flex border-[2px] border-primary rounded-md bg-primary">
              <UserIcon />
            </div>
          </NavLink>
          :
        
            <div
            title={GetAppText("login")}
            onClick={()=>{dispatch(modalHandler("LoginModal"));}} className={`px-2 h-10 whitespace-nowrap ${language === "_ar" ? "text-right " : "flex-row-reverse space-x-reverse"} space-x-2 font-semibold items-center ${location.pathname === "/"&& !isScrolled ? "text-white border-white hover:bg-gray-300" : "text-black border-black hover:bg-gray-200"} justify-center 
            flex border-[2px] lg:text-F14 text-F12 cursor-pointer rounded-md`}>
                  <SvgComponent
                name={"Login"}
                className={"w-4 h-4"}
                stroke={`${location.pathname === "/"&& !isScrolled ?"white":"black"}`}
                fill={"none"}
              /> 
              <div>
              {GetAppText("login")}
              </div>
            </div>
       }

    <div className={`w-[160px] top-14 ${location.pathname === "/" ? "bg-[#FFFFFF40]" : "bg-white"}  px-3 ${language === "_ar" ? "" : ""} ${menuOpen ? "block absolute" : "hidden"} md:hidden`}>
      <div className="w-full md:block md:w-auto" id="navbar-default">
        <ul
          className={`font-semibold space-y-2 w-full flex flex-col py-3 md:p-0 mt-4 md:mt-0 ${
            language === "_ar" ? "flex-row-reverse md:space-x-reverse" : "flex-row"
          }`}
        >
          <li className={`${language === "_ar" ? " text-right" : ""} border-borderColor `}>
            <NavLink
              to={"/"}
              className={({ isActive }) =>
                isActive
                  ? `${location.pathname === "/" ? "text-secondary" : "text-secondary"}`
                  : `${location.pathname === "/" ? "text-white" : "text-black"}`
              }
            >
              {GetAppText("home")}
            </NavLink>
          </li>
          <li className={`${language === "_ar" ? " text-right" : ""} border-borderColor `}>
            <NavLink
              to={"/about-us"}
              className={({ isActive }) =>
                isActive
                  ? `${location.pathname === "/" ? "text-white" : "text-secondary"}`
                  : `${location.pathname === "/" ? "text-white" : "text-black"}`
              }
            >
              {GetAppText("about_us")}
            </NavLink>
          </li>
          <li className={`${language === "_ar" ? " text-right" : ""} border-borderColor `}>
            <NavLink
              to={"/donation-projects"}
              className={({ isActive }) =>
                isActive
                  ? `${location.pathname === "/" ? "text-white" : "text-secondary"}`
                  : `${location.pathname === "/" ? "text-white" : "text-black"}`
              }
            >
              {GetAppText("donationProjects")}
            </NavLink>
          </li>
     
          <li className={`${language === "_ar" ? " text-right" : ""} border-borderColor `}>
            <NavLink
              to={"/technical-support"}
              className={({ isActive }) =>
                isActive
                  ? `${location.pathname === "/" ? "text-white" : "text-secondary"}`
                  : `${location.pathname === "/" ? "text-white" : "text-black"}`
              }
            >
              {GetAppText("technical_support")}
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
        
        </div>

      </div>
  {/* Mobile Screen */}
  <div className={`flex    w-full ${language==="_ar"?"":"flex-row-reverse space-x-reverse"} items-center space-x-2`}>
 { <button
        data-collapse-toggle="navbar-default"
        type="button"
        className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm bg-primary text-white rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
        aria-controls="navbar-default"
        aria-expanded="false"
        onClick={() => setSidebarOpen(!sidebarOpen)}
      >
        <span className="sr-only">Open main menu</span>
        <svg
          className="w-5 h-5"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 17 14"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M1 1h15M1 7h15M1 13h15"
          />
        </svg>
      </button>}
     
    {!showInput &&  <button
        data-collapse-toggle="navbar-default"
        type="button"
        className={`inline-flex items-center p-2 w-10 h-10 justify-center text-sm 
      
            bg-white 
         rounded-lg md:hidden focus:outline-none border-2 border-[#dddddd] focus:ring-2 focus:ring-gray-200`}
        aria-controls="navbar-default"
        aria-expanded="false"
        onClick={() => setShowInput(true)}
      >
      
      <SvgComponent
                    name={"Search"}
                    className={`w-6 h-6`}
                    stroke={"#727272"}
                    fill={"none"}
                  />
      </button>}
      <div className={`relative w-full flex ${
                showInput ? "opacity-100" : "opacity-0 pointer-events-none"
              } bg-red-500 `}>
      <div
      onClick={()=>{setIsDropdownOpen3(true)}}
              className={`md:hidden absolute w-11/12 z-40 inset-0  ${language==="_ar"?"left-0":"left-[20px]"}   flex items-center transition-opacity duration-500 ${
                showInput ? "opacity-100" : "opacity-0 pointer-events-none"
              }`}
            >
              <input
              
                className={`block w-full  py-[10.5px] placeholder:text-F14 text-sm placeholder:text-lightTextColor focus:outline-none focus:border-borderColor focus:ring-0 border-2 border-borderColor rounded-lg ${
                  language === "_ar" ? "pe-2 text-right" : "ps-3"
                }`}
                // onClick={(e)=>{e.stopPropagation(); }}
                type="number"
                value={searchParam}  onChange={(e) => setSearchParam(e.target.value)}
                // value={price} // Controlled input value
                // onChange={(e)=>{  e.stopPropagation();  handlePriceChange(e)}} // Update price on change
                // placeholder="Enter Amount"
                placeholder={GetAppText("search_here")}
              />
              <button
              title={GetAppText("cancel")}
                className={`absolute ${language === "_ar" ? "left-2" : "right-2"
                }   top-1/2 transform -translate-y-1/2 text-gray-400`}
                onClick={(e) =>{  e.stopPropagation();  handleCloseInput()}}
                 // Close input, show button again
              >
                ✕
              </button>
            </div>
            <Dropdown global={true} mainStyle={`!max-h-52 !w-72 !text-F14 ${language === "_ar" ? "left-[8px]" : ""}  top-8 !font-bold`}  handleSelect={handleSelect2} 
             isOpen={isDropdownOpen3} 
             options={Data} 
             selectedValues={selectedValue2}
             setIsDropdownOpen={setIsDropdownOpen3} />
     </div>
      </div>
      {/* Sidebar */}
      {/* from  right to left animatedd */}
      {/* <div
        className={`fixed top-0 ${language === "_ar" ? "right-0" : "left-0"} w-72 h-full md:hidden bg-white shadow-lg z-50 transform transition-transform duration-300 ease-in-out ${
          sidebarOpen ? "translate-x-0" : language === "_ar" ? "translate-x-full" : "-translate-x-full"
        }`}
      > */}
       {/* from  right to left animatedd */}
      <div
  className={`fixed top-0 left-0 w-full p-6 h-auto max-h-screen bg-white shadow-lg z-50 transform transition-transform duration-300 ease-in-out ${
    sidebarOpen ? "translate-y-0" : "-translate-y-full"
  }`}
>
  <div className="flex w-full justify-end items-center">
<button
            onClick={() => setSidebarOpen(false)}
         
          >
            <SvgComponent
                      name={"Cross"}
                      className={"w-3 h-3"}
                      stroke={"#98A2B3"}
                      fill={"none"}
                    />
        
          </button>
          
          </div>
        <div
          className={` flex justify-between items-center pt-2`}
        >
            <div
         
         className={`flex  relative   ${
           language === "_ar" ? "flex-row-reverse space-x-reverse" : "flex-row"
         } items-center space-x-3`}
       >
         <div
          title={GetAppText("language")}
           className={`items-center w-10 h-10 justify-center flex text-black border-black hover:bg-gray-200 border-[2px] rounded-md cursor-pointer`}
           onClick={() => handleToggleLanguage()}
         >
           <span className={`${language === "_ar" ? "" : "-mt-1"} text-F16 font-semibold`}>{langText}</span>
         </div>
         <NavLink to={"/donars-bag"}
           onClick={()=>setSidebarOpen(false)}
         >
           <div
           title={GetAppText("donation_basket")}
             className={`w-10 h-10 items-center text-black border-black hover:bg-gray-200 justify-center flex border-[2px] cursor-pointer rounded-md`}
           >
             
             <SvgComponent
               name={"Cart"}
               className={`w-6 h-5`}
               stroke={"black"}
               fill={"none"}
             />
           </div>
         </NavLink>
         {userToken ? (
           <NavLink to={"/profile"}
           onClick={()=>setSidebarOpen(false)}
           >
             <div className="w-10 h-10 items-center justify-center cursor-pointer flex border-[2px] border-primary rounded-md bg-primary">
               <UserIcon />
             </div>
           </NavLink>
         ) : (
           <div
             onClick={() => {
               setSidebarOpen(false);
               dispatch(modalHandler("LoginModal"));
             }}
             className={`px-4 h-10 ${
               language === "_ar" ? "" : "flex-row-reverse space-x-reverse"
             } space-x-2 font-semibold items-center text-black border-black hover:bg-gray-200 justify-center flex border-[2px] lg:text-F14 text-F12 cursor-pointer rounded-md`}
           >
             <SvgComponent
               name={"Login"}
               className={"w-4 h-4"}
               stroke={"black"}
               fill={"none"}
             />
             <div>{GetAppText("login")}</div>
           </div>
         )}
       </div>
          {/* <div className="flex items-center">
            <SunniLogo />
          </div> */}
        
        </div>
      
        <div className="flex flex-col justify-between space-y-10">
          <ul
            className={`flex flex-col  pt-6 text-F16 font-semibold ${
              language === "_ar" ? "text-right" : "text-left"
            } space-y-5 `}
          >
            {/* Menu Items */}
            <li 
        
            >
              <NavLink 
                to="/"
                className={({ isActive }) =>
                 `  ${ isActive ? "text-secondary" : "text-black"  } flex justify-between items-center`
                }
                onClick={() => {setSidebarOpen(false);
                  setSelectedTab("home")
                }}
              >
                {GetAppText("home")}
                <SvgComponent
                          name={
                           "ChevronRight"
                          }
                          className={"w-5 h-5"}
                          stroke={
                         selectedTab==="home"? "#E49E4E":"#727272"
                          }
                          fill={"none"}
                        />
              </NavLink>
          
            </li>
            <li 
            >
              <NavLink
                to="/about-us"
                className={({ isActive }) =>
                  `  ${ isActive ? "text-secondary" : "text-black"  } flex justify-between items-center`
                }
                onClick={() => {setSidebarOpen(false);
                  setSelectedTab("about")
                }}
              >
                {GetAppText("about_us")}

                <SvgComponent
                          name={
                           "ChevronRight"
                          }
                          className={"w-5 h-5"}
                          stroke={
                         selectedTab==="about"? "#E49E4E":"#727272"
                          }
                          fill={"none"}
                        />
              </NavLink>
             
            </li>
            <li>
              <NavLink
                to="/donation-projects"
                className={({ isActive }) =>
               `  ${ isActive ? "text-secondary" : "text-black"  } flex justify-between items-center`
                }
                onClick={() =>{ setSidebarOpen(false);
                  setSelectedTab("projects")
                }}
              >
                {GetAppText("donate")}
                <SvgComponent
                          name={
                           "ChevronRight"
                          }
                          className={"w-5 h-5"}
                          stroke={
                         selectedTab==="projects"? "#E49E4E":"#727272"
                          }
                          fill={"none"}
                        />
              </NavLink>
           
            </li>
            <li
             className=" "
            
           
            >
              <NavLink
                to="/technical-support"
                className={({ isActive }) =>
               `  ${ isActive ? "text-secondary" : "text-black"  } flex justify-between items-center`
                }
                onClick={() => {setSidebarOpen(false);
                  setSelectedTab("support")
                }}
              >
                {GetAppText("technical_support")}


                <SvgComponent
                          name={
                          "ChevronRight"
                          }
                          className={"w-5 h-5"}
                          stroke={
                         selectedTab==="support"? "#E49E4E":"#727272"
                          }
                          fill={"none"}
                        />
              </NavLink>
           
            </li>
          </ul>

        
        </div>
      </div>

      {/* Overlay to close sidebar */}
      {sidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={() => setSidebarOpen(false)}
        ></div>
      )}
    </div>
  </div>
  <ModalComponent styles="!w-[460px]" isOpen={isloginModal} onClose={()=>{setIsloginModal(false);dispatch(modalHandler(""));}}>
    
    <Login className="" />
   
    </ModalComponent>

    <ModalComponent styles="!w-[460px]" isOpen={isOtpModal} onClose={()=>{setIsOtpModal(false);dispatch(modalHandler(""));}}>
  <Otp update={false}/>
  </ModalComponent>


    <ModalComponent styles="!w-[900px]" isOpen={isSignupnModal} onClose={()=>{setIsSignupModal(false);dispatch(modalHandler(""));}}>
  
  <Signup className="" />
 
  </ModalComponent>
  <ModalComponent styles="!w-[460px]" isOpen={isResetModal} onClose={()=>{setIsResetModal(false);dispatch(modalHandler(""));}}>
 <ResetPassword/>
    </ModalComponent>
</nav>

  );
}

export default Header;

import { Routes, Route } from "react-router-dom";
import Home from "../views/pages/Home";
import TechnicalSupport from "../views/pages/TechnicalSupport";
import AboutUs from "../views/pages/AboutUs";
import DonationProjects from "../views/pages/DonationProjects";
import { Login } from "../views/pages/Login";
import { Otp } from "../views/pages/Otp";
import ProjectDetails from "../views/pages/DonationProjects/projectsDetails";
import DonarsBag from "../views/pages/DonarsBag";
import Checkout from "../views/pages/Checkout";
import StoriesListing from "../views/pages/Story/StoriesListing";
import Story from "../views/pages/Story";
import Signup from "../views/pages/Signup";
import { Profile } from "../views/pages/Profile";
import PrivateRoute from "./private";
import PublicRoute from "./public";
import NotFound from "../views/pages/NotFound";
import News from "../views/pages/News";
import TermsCondition from "../views/pages/TermsCondition";
import Policy from "../views/pages/PrivacyPolicy";
import MosqueDetails from "../views/pages/DonationProjects/mosqueDetails";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/terms-condition" element={<TermsCondition />} />
      <Route path="/privacy-policy" element={<Policy />} />
      <Route path="/donation-projects" element={<DonationProjects />} />
      <Route path="/technical-support" element={<TechnicalSupport />} />
      <Route path="/donation-project/:id" element={<ProjectDetails />} />
      <Route path="/donation-mosque/:id" element={<MosqueDetails />} />
      <Route path="/donars-bag" element={<DonarsBag />} />
      <Route path="/checkout/:id" element={<Checkout />} />
      <Route path="/stories-listing" element={<StoriesListing />} />
      <Route path="/story/:id" element={<Story />} />
      <Route path="/news/:id" element={<News />} />
      {/* <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} /> */}
      {/* <Route path="/otp" element={<Otp />} /> */}
      {/* <Route path="/profile" element={<Profile />} /> */}
       {/* Private Route (Profile) */}
       {/* <Route path="/donars-bag" element={<PrivateRoute element={<DonarsBag />} />} /> */}
       <Route path="/profile" element={<PrivateRoute element={<Profile />} />} />
       {/* <Route path="/checkout" element={<PrivateRoute element={<Checkout />} />} /> */}
   {/* Catch-all route */}
   <Route path="*" element={<NotFound />} /> {/* Add this line */}

    </Routes>
  );
};

// axiosInterceptor.js
import axios from 'axios';
import { BASE_URL } from '../../utils/BaseUrl';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
// Create an instance of Axios


const getDeviceFingerPrint = async () => {
  const fpPromise = await FingerprintJS.load();
  const result = await fpPromise.get();
  return result.visitorId;
};

const getDeviceInfo = async () => {
  const nAgt = navigator.userAgent;
  const nVer = navigator.appVersion;

  // Sample browser/device detection functions
const getBrowserName = () => {
  const edgeRegex = /Edg\/([\d\.]+)/; // Updated regex to detect Edge
  const browsers = {
    Edge: edgeRegex, // Edge detection first
    Firefox: /(?:Firefox|FxiOS)\/([\d\.]+)/,
    Safari: /Version\/([\d\.]+).*Safari/,
    Chrome: /(?:Chrome|CrMo|CriOS)\/([\d\.]+)/,
    IE: /MSIE ([\d\.]+).*Trident/,
    Opera: /(?:Opera|OPR)\/([\d\.]+)/,
  };

  for (const browserName in browsers) {
    const match = nAgt.match(browsers[browserName]);
    if (match) {
      return browserName;
    }
  }
  return 'Unknown';
};

function getBrowserVersion() {
  const userAgent = nAgt;
  const browsers = {
      Firefox: /(?:Firefox|FxiOS)\/([\d\.]+)/,
      Safari: /Version\/([\d\.]+).*Safari/,
      Chrome: /(?:Chrome|CrMo|CriOS)\/([\d\.]+)/,
      Edge: /Edge\/([\d\.]+)/,
      IE: /MSIE ([\d\.]+).*Trident/,
      Opera: /(?:Opera|OPR)\/([\d\.]+)/,
  };
  for (const browserName in browsers) {
      const match = userAgent.match(browsers[browserName]);
      if (match) {
          return match[1];
      }
  }
  return "Unknown";
}


const getDeviceType = () => {
  const isMobileUA = /Mobi|Android/i.test(nAgt);
  const isTabletUA = /Tablet|iPad/i.test(nAgt);
  const isDesktopUA = /Windows NT|Macintosh/i.test(nAgt);

  const screenWidth = window.screen.width;
  const isSmallScreen = screenWidth <= 768;

  if (isDesktopUA && !isSmallScreen) {
    return "Desktop";
  } else if (isTabletUA) {
    return "Tablet";
  } else if (isMobileUA || isSmallScreen) {
    return "Mobile";
  } else {
    return "Unknown";
  }
};

var os = "unknown";
var clientStrings = [
  { s: "Windows 10", r: /(Windows 10.0|Windows NT 10.0)/ },
  { s: "Windows 8.1", r: /(Windows 8.1|Windows NT 6.3)/ },
  { s: "Windows 8", r: /(Windows 8|Windows NT 6.2)/ },
  { s: "Windows 7", r: /(Windows 7|Windows NT 6.1)/ },
  { s: "Windows Vista", r: /Windows NT 6.0/ },
  { s: "Windows Server 2003", r: /Windows NT 5.2/ },
  { s: "Windows XP", r: /(Windows NT 5.1|Windows XP)/ },
  { s: "Windows 2000", r: /(Windows NT 5.0|Windows 2000)/ },
  { s: "Windows ME", r: /(Win 9x 4.90|Windows ME)/ },
  { s: "Windows 98", r: /(Windows 98|Win98)/ },
  { s: "Windows 95", r: /(Windows 95|Win95|Windows_95)/ },
  { s: "Windows NT 4.0", r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
  { s: "Windows CE", r: /Windows CE/ },
  { s: "Windows 3.11", r: /Win16/ },
  { s: "Android", r: /Android/ },
  { s: "Open BSD", r: /OpenBSD/ },
  { s: "Sun OS", r: /SunOS/ },
  { s: "Linux", r: /(Linux|X11)/ },
  { s: "iOS", r: /(iPhone|iPad|iPod)/ },
  { s: "Mac OS X", r: /Mac OS X/ },
  { s: "Mac OS", r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
  { s: "QNX", r: /QNX/ },
  { s: "UNIX", r: /UNIX/ },
  { s: "BeOS", r: /BeOS/ },
  { s: "OS/2", r: /OS\/2/ },
  {
    s: "Search Bot",
    r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
  },
];
for (var id in clientStrings) {
  var cs = clientStrings[id];
  if (cs.r.test(nAgt)) {
    os = cs.s;
    break;
  }
}
var osVersion = "Unknown";
if (/Windows/.test(os)) {
  osVersion = /Windows (.*)/.exec(os)[1];
  os = "Windows";
}
switch (os) {
  case "Mac OS X":
    osVersion = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)[1];
    break;
  case "Android":
    osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[1];
    break;
  case "iOS":
    osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
    osVersion = osVersion[1] + "." + osVersion[2] + "." + (osVersion[3] | 0);
    break;
} 



  const fingerPrintId = await getDeviceFingerPrint();
  return {
    user_agent: nAgt,
    device_width: window.screen.width,
    device_height: window.screen.height,
    browser_name: getBrowserName(),
    browser_version: getBrowserVersion(),
    browser_language: navigator.language,
    device_type: getDeviceType(),
    fingerprint_id: fingerPrintId,
    // Add any other device information you want to track here
    is_mobile: /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(getBrowserName()),
    device_os: os,
    device_os_version: osVersion,
  };
};
const info = await getDeviceInfo();


const axiosInstance = axios.create({
  baseURL: BASE_URL, // Replace with your API base URL
});

// Add request interceptor for attaching token
axiosInstance.interceptors.request.use(
  (config) => {
    // Get token from localStorage or any storage
    const token = localStorage.getItem('token');

   
    if (token) {
      // Attach token to Authorization header
      config.headers.Authorization = `Bearer ${token}`;
    }
    
    if (info?.fingerprint_id) {
      config.headers['X-Fingerprint'] = info?.fingerprint_id;
    }


    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Add response interceptor for token validation
axiosInstance.interceptors.response.use(

  (response) => {
    console.log("Response",response)
    // Return the response data if token is valid
    return response;
  },
  (error) => {
    // Handle response errors like token expiration or invalid token
    if (error.response && error.response.status === 401) {
      console.log('Token expired or invalid');
      localStorage.removeItem('token'); // Optionally remove the token
      window.location.href = '/'; // Redirect to login page
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;

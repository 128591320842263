import React, { useEffect, useState } from "react";

import { useParams } from 'react-router-dom';
import { useSelector,useDispatch } from "react-redux";
import { GetAppText } from "../../../utils";

import { ButtonComponent } from "../../components/ButtonComponent";
import { useNavigate } from 'react-router-dom';
import SvgComponent from "../../components/SvgComponent";
import { SeparatorComponent } from "../../components/Separator";
import { apiRoutes } from "../../../apis/routes";
import { getRequest } from "../../../apis/methods";
import { Loader } from "../../components/Loader";
import { Image_URL } from "../../../utils/BaseUrl";
import { modalHandler } from "../../../store/slices/modalSlice";
import { handleSuccess } from "../../components/Toast/toastHelper";
import { ToastContainer } from "react-toastify";

const Story = () => {
 
        const { id } = useParams();
        const navigate = useNavigate();
        const [loading,setLoading]=useState(false)
        const [isHovered, setIsHovered] = useState(false); 
const[data,setData]=useState('')
const [storyloading,setStoryLoading]=useState(false)
const[storyData,setstoryData]=useState('')
const isOpen = useSelector((state) => state.openModel.value);
        useEffect(()=>{
          fetchData()
          
        },[id])

        useEffect(()=>{

          if(isOpen?.type==="share"){
            handleSuccess(isOpen?.msg,language)
          }
    
        },[isOpen])
        const dispatch = useDispatch();

        const fetchData = async () => {
          setLoading(true)
          try {
            const response = await getRequest(`${apiRoutes.get_storyDetails}?link=${id}`); // Replace with your endpoint
      if(response.status==="success")
         { 
          setLoading(false)
          setData(response?.data?.story)
          setstoryData(response?.data?.similar_stories)
          }
        else{
          setLoading(false)
        }
          } catch (error) {
            setLoading(false)
            console.error('Error fetching data:', error);
          }
        };



        const language = useSelector((state) => state.language.value);


          const handleProjectClick = (id) => {
            console.log("ID",id)
            navigate(`/story/${id}`);
          };
          

          const handle_share=()=>{
            const currentURL = window.location.href;
         
          const fullLink=`${currentURL}`
              // Copy the link to the clipboard
              navigator.clipboard.writeText(fullLink).then(() => {
                dispatch(modalHandler({type:"share",msg:GetAppText("link")}));
                setTimeout(()=>{
                  dispatch(modalHandler(""));
                },100)
              });
          
          }


  return (
    <>
      <ToastContainer />
    <div className="md:px-20 px-5 sm:py-16 py-10 w-full sm:space-y-16 space-y-8">
     
{      loading?    
<div className="flex justify-center items-center h-[540px]">
      <Loader/>
      </div>:
      <div
        className={`md:flex  ${
          language === "_ar" ? " " : "flex-row-reverse md:space-x-reverse "
        } md:space-x-10 md:space-y-0 space-y-7  w-full justify-between`}
      >
<div className="md:w-[40%] ">
        <img 
        
        src={`${Image_URL}${data?.image}`}
        className="sm:h-[430px] h-[460px] object-cover w-full rounded-lg" alt="logo" />
        </div>
        <div className="md:w-[60%] text-primary space-y-7">
          <div
            className={`flex sm:flex-row flex-col-reverse sm:space-y-0 space-y-3 space-y-reverse ${
              language === "_ar" ? "sm:flex-row-reverse  items-end" : "items-start"
            } sm:items-center sm:justify-between`}
          >
            <h1 className="font-extrabold text-F24 ">{language === "_ar" ?data?.title_ar:data?.title}</h1>
            <ButtonComponent
              title={GetAppText("sharing")}
              onClick={()=>{handle_share()}}
              tooltip={GetAppText("link_share")}
              svg={
                <SvgComponent
                  name={"ShareSvg"}
                  className={"w-4 h-4"}
                  stroke={"black"}
                  fill={"none"}
                />
              }
              styles={"!py-2 !px-3 !text-black !w-fit !text-F16"}
              type="secondary_transparent"
            />
          </div>
          <div className={`font-normal ${
          language === "_ar" ? "text-right" : "text-left "} text-F16  `}>{language === "_ar" ?data?.short_description_ar:data?.short_description}</div>

        </div>
      </div>}
<SeparatorComponent/>
<div  className="sm:space-y-8 space-y-6" >

<h1 className={` flex ${
          language === "_ar" ? "flex-row-reverse " : " "}
        font-semibold text-primary text-F30 `}>{GetAppText("other_story")}</h1>

    <div className={`flex flex-wrap sm:gap-6 gap-1 sm:justify-center items-center ${language==="_ar"?"justify-end":""}`}>
{/* {images&&images?.map((item)=>(
<img src={item?.image} className="rounded-lg" alt="logo"/>

))} */}
     { storyloading ?
         <div className="flex h-[250px] w-full justify-center items-center">
         <Loader/>
         </div>
         : storyData?.length>0? 
         storyData.map((item,index)=>
(
  id!==item?.id &&

<div
 key={item?.id}
      onMouseEnter={() => setIsHovered(true)}  // Set hover state to true on hover
      onMouseLeave={() => setIsHovered(false)} // Set hover state to false when not hovering
    className={`${isHovered && 'relative group'} rounded-lg  font-semibold flex justify-center items-center cursor-pointer sm:h-[240px] sm:w-[410px] w-[165px] h-[120px]`} 
   
    onClick={() => handleProjectClick(item?.slug)}
    >
        {/* Image */}
        <img
         className="rounded-lg sm:h-[240px] w-[165px] h-[120px] object-cover sm:w-[410px]" alt="logo"
          // className="rounded-t-[8px] h-52 object-cover w-full"
          src={`${Image_URL}${item?.image}`}
        />

        {/* Overlay with black blur and title (hidden by default) */}
        {isHovered && (
        <div
          className={`absolute inset-0 z-20 bg-black bg-opacity-50 opacity-0 ${isHovered && 'group-hover:opacity-100'}  flex justify-center items-center transition-opacity duration-300 rounded-t-[8px]`}
          style={{ backdropFilter: `blur(1px)` }}
        >
          <h1 className="text-white font-bold sm:text-F20 text-F12">
            {language === "_ar" ? item?.title_ar : item?.title}
          </h1>
        </div>)}
      </div>
))  
:
(!storyloading && <div className="flex w-full justify-center text-F20 font-semibold items-center">
             {GetAppText("no_data")}
              </div>)}
</div>
</div>
  </div>
  </>
  );
};

export default Story;

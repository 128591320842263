import { configureStore } from "@reduxjs/toolkit";
import langugeReducer from "./slices/languageSlice.js";
import sidebarOptions from "./slices/sideBarSlice.js";
import ModalSlicer from "./slices/modalSlice.js"
import DataSlicer from"./slices/dataSlice.js"
import UserDataSlicer from"./slices/userDetailsSlice.js"
import infoSlice from "./slices/infoSlice.js";
import donationSlice from "./slices/donationSlice.js";
import  countrySlice  from "./slices/countrySlice.js";
import  settingSlice  from "./slices/settingsSlice.js";
export const store = configureStore({
  reducer: { country:countrySlice,donation:donationSlice,
    setting:settingSlice,language: langugeReducer, selectedOption: sidebarOptions,
    openModel:ModalSlicer,data:DataSlicer,userDetails:UserDataSlicer,info:infoSlice },
});

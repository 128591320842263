import React, { useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import { GetAppText } from "../../../utils";
import { InputComponent } from "../../components/InputComponent";
import { ButtonComponent } from "../../components/ButtonComponent";

import {ReactComponent as Texture} from "../../../assets/svgs/texture3.svg"
import { modalHandler } from "../../../store/slices/modalSlice";
export const ResetPassword = () => {
  const language = useSelector((state) => state.language.value);

  const dispatch = useDispatch();

const [isPasswordVisible, setIsPasswordVisible] = useState(false);
const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
const handleOtp=()=>{
  dispatch(modalHandler("OtpModal"));
}

  return (
 
      <div
        className={`bg-primary relative w-full rounded-lg p-6  ${
          language === "_ar" ? "text-right" : "text-left"
        }`}
      >
         <div className="absolute z-50 top-0 left-0">
     <Texture className="object-cover"/>
      </div>
      <div className="space-y-6">
        <div className={`text-white ${
          language === "_ar" ? "text-right" : "text-left"
        } font-bold text-F24`}>
          {GetAppText("enter_password")}
        </div>
        <div className=" w-full">
            
          <div className="flex flex-col space-y-4 ">
          <div className="flex flex-col  space-y-2 mb-14">

              <h1 className="text-white text-F14">{GetAppText("password")}</h1>
              <div className="relative">
              <div className="absolute z-50 w-full">
              <InputComponent 
              name={"password"}
              type={isPasswordVisible ? "text" : "password"}
              toggleVisibility={
                () => setIsPasswordVisible(!isPasswordVisible)
               
              }
              isPasswordVisible={ isPasswordVisible }
              
              />
              </div>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <h1 className="text-white text-F14">{GetAppText("confirm_password")}</h1>
              <InputComponent 
              name={"confirm_password"}
              type={isConfirmPasswordVisible ? "text" : "password"}
              toggleVisibility={
                () => setIsConfirmPasswordVisible(!isConfirmPasswordVisible) }
              isPasswordVisible={ isConfirmPasswordVisible}
              
              />
            </div>
          </div>
      
          <div className="pt-6 w-full"> 
            <div className="space-y-4 w-full">
              <ButtonComponent styles={"!w-full !py-2.5"} onClick={handleOtp} title={GetAppText("change_password")} />
              
            </div>
          </div>
        </div>
        </div>
      </div>

  );
};

import React, { useEffect, useState } from "react";
import { GetAppText } from "../../../../utils";
import { useSelector } from "react-redux";
import { AccordionItem } from "../../../components/Accordian";

import { getRequest } from "../../../../apis/methods";
import { apiRoutes } from "../../../../apis/routes";
import { Loader } from "../../../components/Loader";

const Faqs = () => {
  const language = useSelector((state) => state.language.value);

  const [faqloading, setfaqLoading] = useState(false);
  const [faqdata, setFaqData] = useState("");
  const categories = [
    { type: 'GENERAL_QUESTIONS', title: language === '_ar' ? 'الأسئلة العامة' : 'General Questions' },
    { type: 'DONATION_AND_PROJECTS', title: language === '_ar' ? 'التبرعات والمشاريع' : 'Donation and Projects' },
    { type: 'ACCOUNT_AND_REGISTRATION', title: language === '_ar' ? 'الحساب والتسجيل': 'Account and Registration' },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const fetchData = async () => {
    setfaqLoading(true);
    try {
      const response = await getRequest(`${apiRoutes.get_faqs}`); // Replace with your endpoint
      if (response.status==="success") {
        setfaqLoading(false);
        setFaqData(response?.data)

        console.log("Results", response);
      } else {
        setfaqLoading(false);
      }
    } catch (error) {
      setfaqLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className={`w-full sm:space-y-6 space-y-4`}>
      <h2
        className={`flex items-center ${
          language === "_ar" ? "flex-row-reverse  space-x-reverse" : ""
        } text-primary text-F24 font-bold `}
      >
        {GetAppText("frequently_asked_questions")}
      </h2>

      {faqloading ? (
        <div className="flex h-[440px] w-full justify-center items-center">
          <Loader />
        </div>
      ) : (
    
        <div className="space-y-5">
{/* <div className="w-full bg-offWhite p-6 rounded-xl space-y-7"> */}
      {/* Loading state */}
      {/* {faqLoading && <p>Loading FAQs...</p>} */}

      { faqdata.length > 0 && categories.map((category) => {
        const categoryFaqs = faqdata?.filter((item) => item.type === category.type); // Filter by category type

        return (
          categoryFaqs.length > 0 && (
         
            <div key={category.type} className="w-full bg-offWhite p-6 rounded-xl space-y-7">
              {/* Category Heading */}
              <h2 className={` ${language==="_ar"?'text-right text-F20':'text-F18'} font-bold text-secondary`}>
                {category.title}
              </h2>

              {/* FAQs under the current category */}
              {categoryFaqs.map((item, index) => (
                <AccordionItem
                  key={index}
                  index={index}
                  title={language === "_ar" ? item.question_ar : item.question}
                  content={language === "_ar" ? item.answer_ar : item.answer}
                  isOpen={openIndex === item?.id}
                  onToggle={() => toggleAccordion(item?.id)}
                />
              ))}
            </div>
         
          )
        );
      })}
    </div>
      )}
    </div>
  );
};

export default Faqs;

import React, { useRef, useState } from 'react';
import { InputComponent } from '../InputComponent';
import { ButtonComponent } from '../ButtonComponent';
import { GetAppText, ValidationErrors } from "../../../utils";

import "../../../App.css"
import { useSelector,useDispatch } from "react-redux";
import { modalHandler } from "../../../store/slices/modalSlice";
import { useNavigate } from 'react-router-dom';
import { apiRoutes } from '../../../apis/routes';
import { postRequest } from '../../../apis/methods';
const QuickDonate = ({ data }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(null); // track selected amount

  const language = useSelector((state) => state.language.value);
  const [isCustomAmount, setIsCustomAmount] = useState(false);

  const[addloading,setAddLoading]=useState(false)
  const[donateloading,setDonateLoading]=useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
const InpRef=useRef('')
  const handleAddtoCart = async (item) => {
    console.log("New handle submit")

    // Prevent multiple submissions
  if (addloading) return; 
  // Prevent multiple submissions
  console.log("DATAA",data)

    // const payload = {
    //   itemable_type: "category",
    //   itemable_id:selectedCategory,
  
    //   price: selectedAmount  
    // };
   
    const payload = {
      cart_type:"CART",
      is_edit:false,
      items: [
          {
              id:selectedCategory,
              type:"CATEGORY",
              price: selectedAmount
          }
      ]}
  

    console.log("payload",payload)
  if(selectedAmount && selectedCategory){
    setAddLoading(true)
 
  
    try {
      const response = await postRequest(apiRoutes.add_cart, payload); // Replace with your endpoint
      console.log("Response", response);
   
      if (response.status === "success") { 
        setAddLoading(false)
     
        let error = '';
        if (
          response?.message?.includes(ValidationErrors.addCartItem.added)
        ) {
          error = GetAppText('added_cart');
        
        } 
        else if (
          response?.message?.includes(ValidationErrors.addCartItem.updated)
        ) {
          error = GetAppText('updated_cart');
         
        } 

        dispatch(modalHandler({ type: "toast", msg: error}));
        setTimeout(()=>{
            dispatch(modalHandler(""));
          },[500])
  
    
   
      } else {
        setAddLoading(false)
        let error=""
        if (
          response?.data?.message?.includes(ValidationErrors.addCartItem.limit_error)
        ) {
          error = GetAppText('limit_price_cart');
     
        } 
        else {
          error = GetAppText('something_went_wrong');
        }
        // setIsAddModal(false)
        dispatch(modalHandler({ type: "toastError", msg: error}));
        setTimeout(()=>{
            dispatch(modalHandler(""));
          },[500])
     
       
      }
    } 
    catch (error) {
      console.error('Error fetching data:', error);
    }
  

  
  }
    else{
        setAddLoading(false)
     
        dispatch(modalHandler("fieldError"));
        setTimeout(()=>{
            dispatch(modalHandler(""));
          },[500])

    }
  
  };

const handleDonate=async()=>{
  console.log("New handle submit")

  // Prevent multiple submissions
if (donateloading) return; 
// Prevent multiple submissions
// console.log("DATAA",data)
//   const payload = {
//     itemable_type: "category",
//     itemable_id:selectedCategory,

//     price: selectedAmount  
//   };
 
  const payload = {
    cart_type:"DONATION",
    is_edit:false,
    items: [
        {
            id:selectedCategory,
            type:"CATEGORY",
            price: selectedAmount
        }
    ]}


  console.log("payload",payload)
if(selectedAmount && selectedCategory){
  setDonateLoading(true)

try {
  const response = await postRequest(apiRoutes.add_cart, payload); // Replace with your endpoint
  console.log("Response", response);

  if (response.status === "success") { 
    setDonateLoading(false)
  
    dispatch(modalHandler(""))
    // dispatch(donationHandler("Donate"))
    // localStorage.setItem('Donate',true)
    navigate(`/checkout/donation`);
  

  } else {
    setDonateLoading(false)
    // setIsAddModal(false)
    let error=""
    if (
      response?.data?.message?.includes(ValidationErrors.addCartItem.limit_error)
    ) {
      error = GetAppText('limit_price_cart');
 
    } 
    else {
      error = GetAppText('something_went_wrong');
    }
    // setIsAddModal(false)
    dispatch(modalHandler({ type: "toastError", msg: error}));
    setTimeout(()=>{
        dispatch(modalHandler(""));
      },[500])

   
  }
} 
catch (error) {
  console.error('Error fetching data:', error);
}

}

else{
  setDonateLoading(false)

  dispatch(modalHandler("fieldError"));
  setTimeout(()=>{
      dispatch(modalHandler(""));
    },[500])

}

}

  // Handle quick amount selection
  const handleQuickAmountClick = (amount) => {
    InpRef.current.focus();
    setIsCustomAmount(false); // Disable custom amount
    setSelectedAmount(amount); // Set the selected predefined amount
  };

  // Handle custom amount input
  const handleCustomAmountChange = (e) => {
    setIsCustomAmount(true); // Indicate that user is typing custom amount
    setSelectedAmount(e.target.value); // Set custom input value as the selected amount
  };


  return (
    <div>
      <div className='space-y-3 sm:h-[335px] h-[250px] mb-3 overflow-y-auto custom-scrollbar px-1 pb-3'>
        <div className={`${language === '_ar' ? 'text-right' : 'text-left'} text-F16 font-semibold text-white`}>
          {GetAppText('select_category')}
        </div>

        {/* Categories Section */}
        <div className={`flex ${language === '_ar' ? 'flex-row-reverse' : ''} flex-wrap gap-4`}>
          {data?.categories?.map((item, index) => (
            <div
              key={index}
              onClick={() => setSelectedCategory(item.id)} // Set selected category
              className={`sm:w-[152px] w-[100px] h-[48px]  flex items-center justify-center border text-F16 rounded-md border-white cursor-pointer
                ${selectedCategory === item.id ? 'bg-white text-black' : 'text-white'}`} // Change background when selected
            >
              {language === '_ar' ? item?.title_ar : item?.title}
            </div>
          ))}
        </div>

        {/* Amounts Section */}
        <div className={`${language === '_ar' ? 'text-right' : 'text-left'} pt-6 text-F16 font-semibold text-white`}>
          {GetAppText('choose_amount')}
        </div>
        <div className={`flex ${language === '_ar' ? 'flex-row-reverse' : ''} flex-wrap gap-4`}>
          {data?.quick_amounts?.map((item, index) => (
            <div
              key={index}
              onClick={() => handleQuickAmountClick(item.amount)} // Set selected amount
              className={`sm:w-[152px]  w-[100px] h-[48px] gap-1 font-bold ${language === '_ar' ? 'flex-row-reverse' : ''} flex items-center justify-center border text-F16 rounded-md border-white cursor-pointer
                ${selectedAmount === item.amount ? 'bg-white text-black' : ' text-white'}`} // Change background when selected
            >
              <div>{item?.amount}</div>
              <div>{GetAppText('bhd')}</div>
            </div>
          ))}
        </div>
      </div>

      {/* Input and Buttons Section */}
      <div>
      <InputComponent
      ref={InpRef}
            title="donation_amount"
            value={ selectedAmount } // Clear input if quick amount is selected
            onChange={handleCustomAmountChange} // Update custom amount
            type="number"
          />
        <div
          className='w-full pt-6 sm:flex-nowrap flex-wrap'
          style={{
            display: 'flex',
            flexDirection: language === '_ar' ? 'row-reverse' : 'row',
            gap: 10,
          }}
        >
          <ButtonComponent loading={addloading} disabled={addloading || donateloading} onClick={handleAddtoCart} styles={"!w-full !py-2.5 !text-F18 !bg-[#697B84]"} title={GetAppText('add_cart')} type="primary" />
          <ButtonComponent loading={donateloading} disabled={addloading || donateloading} onClick={handleDonate} styles={"!w-full !py-2.5 !text-F18"} title={GetAppText('donate_now')} />
        </div>
      </div>
    </div>
  );
};

export default QuickDonate;

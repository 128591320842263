import React from "react";

const Pagination = ({ currentPage, lastPage, onPageChange }) => {
  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if(currentPage !== lastPage)
  {  if (currentPage < lastPage) {
      onPageChange(currentPage + 1);
    }}
    else {
        return''
    }
  };

  const renderPageNumbers = () => {
    const pages = [];
    for (let i = 1; i <= lastPage; i++) {
      pages.push(
        <li key={i}>
          <div
          
            onClick={() => onPageChange(i)}
            style={{backgroundColor:currentPage === i?'rgba(206, 136, 56, 0.05) ':'white'}}
            className={`flex items-center justify-center px-3 py-2 h-10  ${
              currentPage === i
                ? "text-secondary border border-gray-300 text-F16  hover:bg-blue-100 hover:text-secondary font-bold dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                : "text-gray-500 cursor-pointer text-F14 border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            }`}
          >
            {i}
          </div>
        </li>
      );
    }
    return pages;
  };

  return (
    <div >
      <ul className="flex items-center -space-x-px h-10 text-sm">
        <li>
          <div
        
            onClick={handlePrevious}
            className={`flex items-center justify-center px-3 h-10 ms-0 py-2 leading-tight ${
              currentPage === 1
                ? "text-gray-300 bg-gray-100 border border-e-0 border-gray-300 cursor-not-allowed"
                : "text-secondary bg-white border cursor-pointer border-e-0 border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            } rounded-s-lg`}
            aria-disabled={currentPage === 1}
          >
            <span className="sr-only">Previous</span>
            <svg className="w-3 h-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4" />
            </svg>
          </div>
        </li>

        {renderPageNumbers()}

        <li>
          <div
          
            onClick={handleNext}
            className={`flex items-center justify-center px-3 h-10 py-2 leading-tight ${
              currentPage === lastPage
                ? "text-gray-300 bg-gray-100 border border-gray-300 cursor-not-allowed"
                : "text-secondary bg-white border cursor-pointer border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            } rounded-e-lg`}
            aria-disabled={currentPage === lastPage}
          >
            <span className="sr-only">Next</span>
            <svg className="w-3 h-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
            </svg>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;

import { useSelector } from "react-redux";
import en from "../utils/Language/en";
import ar from "../utils/Language/ar";
import { store } from "../store";

export const GetAppText = (key) => {
  const currentLang = store.getState().language.value;

  switch (currentLang) {
    case "_en":
      return en[key];
    case "_ar":
      return ar[key];
    default:
      return en[key];
  }
};

export const StyleClass = () => {
  const currentLang = store.getState().language.value;

  return currentLang === "_ar" ? "flex-row-reverse space-x-reverse" : "";
};

export const secondaryColor = "#E49E4E";

export const ValidationErrors = {
  email: {
    alreadyExists: 'the-email-has-already-been-taken',
    invalid: 'the-email-field-must-be-a-valid-email-address.',
  },
  phoneNumber: {
    alreadyExists: 'this-phone-number-is-already-in-use',
    notComplete: 'the-phone-number-must-be-exactly',
    notExists: 'user-signup-required',
  },
  cprNumber: {
    alreadyExists: 'the-cpr-number-has-already-been-taken',
    notComplete: 'the-cpr-number-field-must-have-at-least-9-digits',
  },
  Otp:{
    notValid:"verification-code-not-valid-or-phone-not-exist"
  },
  updateProfile:{
    update:"update-user-info",
    phoneUpdated:"phone-successfully-updated"
  },
  addCartItem:{
    added:"cart-item-added-successfully",
    limit_error:"the-item-price-must-not-exceed-50000.",
    updated:"cart-item-updated-successfully",
    remove:"cart-item-remove-successfully"
  }
};
// src/components/PrivateRoute.js
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/authContext";  // Access AuthContext to check authentication


const PrivateRoute = ({ element }) => {
    console.log("element",element)
  const { isAuthenticated, loading  } = useAuth();  // Check if the user is authenticated
  if (loading) {
    return null; // Avoid rendering anything until authentication is checked
  }


  return isAuthenticated ? element : <Navigate to="/"  replace />;  // Redirect if not authenticated
};

export default PrivateRoute;

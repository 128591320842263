// toastHelpers.js

import { toast } from 'react-toastify';

// Reusable CloseButton component
export const CloseButton = ({ closeToast, color }) => (
  <button onClick={closeToast} style={{ color: color, marginRight: 7, marginLeft: 7 }}>
    &#x2716;
  </button>
);

// handleClick function
export const handleClick = (item, language) => {
  toast.success(
    <div style={{ color: '#E94747' }} className={`text-F16 ${language === "_ar" ? "text-right" : "text-left"}`}>
      {item}
    </div>,
    {
      style: {
        display: "flex",
        backgroundColor: "#FAEAEA",
        flexDirection: `${language === "_ar" ? "row-reverse" : "row"}`,
      },
      position: `${language === "_ar" ? "top-right" : "top-left"}`,
      autoClose: 1500,
      hideProgressBar: true,
      icon: false,
      closeButton: <CloseButton color="#E94747" />
    }
  );
};

// handleSuccess function
export const handleSuccess = (item, language) => {
  toast.success(
    <div style={{ color: '#E49E4E' }} className={`text-F16 ${language === "_ar" ? "text-right" : "text-left"}`}>
      {item}
    </div>,
    {
      style: {
        backgroundColor: "#FAECDC",
        display: "flex",
        flexDirection: `${language === "_ar" ? "row-reverse" : "row"}`,
      },
      position: `${language === "_ar" ? "top-right" : "top-left"}`,
      autoClose: 3000,
      hideProgressBar: true,
      icon: false,
      closeButton: <CloseButton color="#E49E4E" />
    }
  );
};

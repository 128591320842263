import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: null,
};

export const DonationSlice = createSlice({
  name: "donation",
  initialState,
  reducers: {
    donationHandler: (state, action) => {
      console.log("Option", action.payload);
      state.value = action.payload;
    },
  },
});

export const { donationHandler } = DonationSlice.actions;

export default DonationSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: null,
};

export const CountrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {
    countryHandler: (state, action) => {
      console.log("Option", action.payload);
      state.value = action.payload;
    },
  },
});

export const { countryHandler } = CountrySlice.actions;

export default CountrySlice.reducer;

import React, { useEffect, useRef, useState } from "react";
import { GetAppText, ValidationErrors } from "../../../utils";
import { InputComponent } from "../../components/InputComponent";

import { ButtonComponent } from "../../components/ButtonComponent";

import { AlertComponent } from "../../components/AlertComponent";

import { ReactComponent as Texture } from "../../../assets/svgs/texture3.svg";
import SvgComponent from "../../components/SvgComponent";
import Dropdown from "../../components/Dropdown";

import { useSelector, useDispatch } from "react-redux";
import { modalHandler } from "../../../store/slices/modalSlice";
import { dataHandler } from "../../../store/slices/dataSlice";
import { getRequest, postRequest } from "../../../apis/methods";
import { apiRoutes } from "../../../apis/routes";
const Signup = () => {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.value);
  const [payload, setPayload] = useState({
    name: "",
    nationality: "",
    phone: "",
    personal_number: "",
    email: "",
    password: "", // Added password
    confirm_password: "", // Added confirm_password
  });

  // const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  // const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const [selectedValue2, setSelectedValue2] = useState("");
  const [selectedValue3, setSelectedValue3] = useState(1);
  const [selectedValue, setSelectedValue] = useState("+973");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState("");
  // const [countryData, setCountryData] = useState("");
  const countryData = useSelector((state) => state.country.value);
  const [digitLenght, setDigitLenght] = useState(8);
  const inputRef = useRef(null);
  useEffect(() => {
    setSelectedValue2(language === "_ar" ? "البحرين" : "Bahrain");
  }, []);

  //   const fetchData = async () => {
  //     // setLoading(true)
  //     try {
  //       const response = await getRequest(apiRoutes.countries); // Replace with your endpoint
  // if(response.success)
  //    {
  //     // setLoading(false)
  //     setCountryData(response?.data?.countries)
  //     console.log("Results",response)
  //   }
  //   else{
  //     // setLoading(false)
  //   }
  //     } catch (error) {
  //       setLoading(false)
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  const data = [
    { id: 1, name: "name", type: "text" },
    { id: 2, name: "nationality", type: "text" },
    { id: 3, name: "phone", type: "tel" },
    { id: 4, name: "personal_number", type: "number" },
    { id: 5, name: "email", type: "email" },
  ];

  // const pass=[
  //     {id:1,name:"password",type: isPasswordVisible ? "text" : "password"},
  //     {id:2,name:"confirm_password",type: isConfirmPasswordVisible ? "text" : "password" },
  // ]

  // const handleChange = (e) => {
  //     const { name, value } = e.target;
  //     setPayload((prevPayload) => ({
  //       ...prevPayload,
  //       [name]: value,
  //     }));
  //   };
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Limit the value length for personal_number
    if (name === "personal_number" && value.length > 9) {
      return; // Prevent the user from entering more than 9 digits
    }

    setPayload((prevPayload) => ({
      ...prevPayload,
      [name]: value,
    }));
  };

  const handleLogin = () => {
    // navigate(`/login`);
    dispatch(modalHandler("LoginModal"));
  };
  const handleSubmit = async () => {
    const formattedPhone = Number(selectedValue.replace("+", ""));
    console.log("formattedPhone", formattedPhone);
    const number = Number(phoneNumber);
    const combinedPhone = `${formattedPhone}${number}`;
    const data = {
      name: payload?.name,
      country_id: selectedValue3,
      email: payload?.email,
      // phone_code: selectedValue,
      phone: Number(combinedPhone),
      cpr_number: payload?.personal_number,
    };
    setLoading(true);
    if (
      payload?.name &&
      payload?.personal_number &&
      payload?.email &&
      selectedValue &&
      phoneNumber &&
      selectedValue2
    ) {
      try {
        const response = await postRequest(apiRoutes.register, data); // Replace with your endpoint
        if (response.status === "success") {
          setLoading(false);
          // setData(response?.data)
          console.log("Results", response);
          dispatch(modalHandler("OtpModal"));
          dispatch(dataHandler(data));
        } else {
          console.log("response",response)
          if (response?.data?.message?.includes(ValidationErrors.email.invalid)) {
            setAlert(GetAppText("email_must_be_valid"));
          } else if (
            response?.data?.message?.includes(
              ValidationErrors.phoneNumber.alreadyExists
            )
          ) {
            setAlert(GetAppText("phone_already_exists"));
          } 
          else if (
            response?.data?.message?.includes(
              ValidationErrors.email.alreadyExists
            )
          ) {
            setAlert(GetAppText("email_already_exists"));
          }
          else if (
            response?.data?.message?.includes(ValidationErrors.phoneNumber.notComplete)
          ) {
            setAlert(GetAppText("phone_not_valid"));
          } 
          else if (
            response?.data?.message?.includes(ValidationErrors.cprNumber.alreadyExists)
          ) {
            setAlert(GetAppText("cpr_already_exists"));
          }
          else if (
            response?.data?.message?.includes(ValidationErrors.cprNumber.notComplete)
          ) {
            setAlert(GetAppText("cprNumberNotComplete"));
          } else {
            setAlert(GetAppText("something_went_wrong"));
          }
          // setAlert(response?.data?.message)
          setTimeout(() => {
            setAlert("");
          }, [2000]);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    } else {
      setLoading(false);
      setAlert("All fields are required");
      setTimeout(() => {
        setAlert("");
      }, [2000]);
    }
    console.log("Final Payload:", data);
    // Here, you can send the payload to an API or perform any other action needed.
  };

  const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handler = () => {
    console.log("helooo");
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSelect = (value) => {
    setDigitLenght(value?.phone_digits);
    setSelectedValue(value?.phone_code);
    inputRef.current.focus();
    setPhoneNumber("");
    setIsDropdownOpen(false);
  };

  const handler3 = () => {
    console.log("helooo");
    setIsDropdownOpen3(!isDropdownOpen3);
  };

  const handleSelect2 = (value) => {
    if (language === "_ar") {
      setSelectedValue2(value?.name_ar);
    } else {
      setSelectedValue2(value?.name);
    }
    setSelectedValue3(value?.id);
    setDigitLenght(value?.phone_digits);
    setSelectedValue(value?.phone_code);
    inputRef.current.focus();
    setPhoneNumber("");
    setIsDropdownOpen3(false);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;

    // Allow only numbers and limit to 8 digits
    const numericValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    if (numericValue.length <= digitLenght) {
      setPhoneNumber(value);
    }
  };
  const handleKeyDown = (event) => {
    if (["e", "E", ".", "-", "+"].includes(event.key)) {
      event.preventDefault();
    }
  };
  return (
    <div
      className={`bg-primary sm:h-[460px] h-[620px] relative p-6 rounded-xl  ${
        language === "_ar" ? "text-right" : "text-left"
      }`}
    >
      <div className="absolute z-10  top-0 left-0">
        <Texture className="object-cover" />
      </div>
      <div className="sm:space-y-6 space-y-0 relative">
        <h1 className={`text-white font-bold text-F24 text-center`}>
          {GetAppText("createNewAccount")}
        </h1>
        <div className="z-20 absolute w-full">
          <div className="sm:flex sm:flex-col w-full ">
            <div
              className={`sm:flex sm:flex-wrap  gap-y-5 ${
                language === "_ar" ? "flex-row-reverse " : ""
              } justify-between`}
            >
              {data &&
                data?.map((item) => (
                  <div
                    key={item?.id}
                    className="sm:w-[49%] sm:space-y-1 sm:pt-0 pt-1 space-y-2"
                  >
                    <h1 className="text-white  text-F14">
                      {GetAppText(item.name)}
                    </h1>
                    {item.name === "name" ||
                    item.name === "email" ||
                    item.name === "personal_number" ? (
                      <InputComponent
                        type={item.type}
                        value={payload[item.name]}
                        onChange={handleChange}
                        maxLength={item.name === "personal_number" && 9}
                        name={item.name}
                      />
                    ) : item?.name === "nationality" ? (
                      <div className="w-full relative">
                        <ButtonComponent
                          onClick={handler3}
                          title={selectedValue2}
                          svg={
                            <SvgComponent
                              name={"ChevronDown"}
                              className={`w-2.5 h-2.5`}
                              stroke={"#1B1C1E"}
                              fill={"none"}
                            />
                          }
                          styles={
                            "py-3 w-full flex  !border-2 !border-borderColor !rounded-lg justify-between px-4 text-sm whitespace-nowrap overflow-hidden text-ellipsis !bg-white"
                          }
                          type="secondary_transparent"
                        />
                        <Dropdown
                          countryName={true}
                          mainStyle={`w-full !h-[200px]`}
                          handleSelect={handleSelect2}
                          isOpen={isDropdownOpen3}
                          options={countryData}
                          setIsDropdownOpen={setIsDropdownOpen3}
                        />
                      </div>
                    ) : item?.name === "phone" ? (
                      <div>
                        <div className="flex items-center  py-1 bg-white border-2 border-borderColor  rounded-lg ">
                          <div
                            onClick={handler}
                            className="flex cursor-pointer items-center px-2.5 space-x-2 border-e border-e-borderColor"
                          >
                            <div className="text-sm">{selectedValue}</div>
                            <SvgComponent
                              name={"ChevronDown"}
                              className={`w-2.5 h-1.5`}
                              stroke={"black"}
                              fill={"none"}
                            />
                          </div>
                          <input
                            type={"number"}
                            onKeyDown={handleKeyDown}
                            id={"phone"}
                            value={phoneNumber}
                            onChange={handleInputChange}
                            className={`block no-spinner w-full border-none focus:border-borderColor focus:outline-none focus:ring-0  placeholder:text-F14 text-sm placeholder:text-lightTextColor ${
                              language === "_ar" ? "pe-2 text-right" : "ps-3"
                            } `}
                            ref={inputRef}
                            name={"phone"}
                          />
                        </div>
                        {isDropdownOpen && (
                          <Dropdown
                            phone={true}
                            mainStyle={`w-full !h-[200px] `}
                            handleSelect={handleSelect}
                            isOpen={isDropdownOpen}
                            options={countryData}
                            setIsDropdownOpen={setIsDropdownOpen}
                          />
                        )}
                      </div>
                    ) : null}
                  </div>
                ))}
            </div>
          </div>
          {alert && (
            <AlertComponent
              text={alert}
              isArb={false}
              svgName={"Warning"}
              svgColor={"#E94747"}
              styles={"!text-errorColor !bg-alertErrorBg !mt-2"}
            />
          )}
          {/* <SeparatorComponent className="!my-6 !border-[#556770]"/> */}

          {/* <SeparatorComponent className="!my-5 !border-[#556770]"/>
       <div className={`flex flex-wrap  gap-y-5  ${  language === "_ar" ? "flex-row-reverse " : "" } justify-between`}>
              {pass && pass?.map((item,index)=>(
                   <div key={item?.id} className="w-[49%] space-y-1">
                   <h1 className="text-white text-F14">{GetAppText(item.name)}</h1>
                   <InputComponent input_style={"!py-3"}
            type={item.type}
            value={payload[item.name]}
              onChange={handleChange}
              name={item.name}
              toggleVisibility={
                index === 0
                  ? () => setIsPasswordVisible(!isPasswordVisible)
                  : () => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
              }
              isPasswordVisible={
                index === 0 ? isPasswordVisible : isConfirmPasswordVisible
              }

            />
                 </div>
              ))
           }
            </div> */}

          <div className={`${alert?'sm:pt-4 pt-2':'sm:pt-8 pt-4'} sm:pb-2  w-full`}>
            <div
              className={` w-full sm:flex items-center justify-between sm:space-x-3 space-y-3 sm:space-y-0 ${
                language === "_ar" ? "flex-row-reverse sm:space-x-reverse" : ""
              }`}
            >
              <ButtonComponent
                disabled={loading}
                loading={loading}
                onClick={handleSubmit}
                styles={"!w-full !py-2.5"}
                title={GetAppText("complete_registration")}
              />
              <ButtonComponent
                styles={"!w-full !py-2.5"}
                onClick={handleLogin}
                title={GetAppText("have_an_account")}
                type="transparent"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;

import React, { useEffect, useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import { GetAppText, ValidationErrors } from "../../../utils";

import { ButtonComponent } from "../../components/ButtonComponent";
import { useParams } from 'react-router-dom';
import SvgComponent from "../../components/SvgComponent";
import { SeparatorComponent } from "../../components/Separator";
import { ProgressBar } from "../../components/ProgressBar";
import { DonationCard } from "../../components/DonationCard";
import { InputComponent } from "../../components/InputComponent";

import { apiRoutes } from "../../../apis/routes";
import { getRequest, postRequest } from "../../../apis/methods";
import { Loader } from "../../components/Loader";
import { useNavigate } from 'react-router-dom';
import { modalHandler } from "../../../store/slices/modalSlice";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleClick, handleSuccess } from '../../components/Toast/toastHelper';
import DonationModal from "../../components/DonationModal";
import { Image_URL } from "../../../utils/BaseUrl";
const MosqueDetails = () => {
  const settingData = useSelector((state) => state?.setting?.value);
  const language = useSelector((state) => state.language.value);
  const isOpen = useSelector((state) => state.openModel.value);
  const [loading,setLoading]=useState(false)
  const[data,setData]=useState('')
  const [addloading,setAddLoading]=useState(false)
  const [donateloading,setDonateLoading]=useState(false)
  const dispatch = useDispatch();
  const [price,setPrice]=useState("")
  const[similarData,setSimilarData]=useState('')
  const [isAddModal, setIsAddModal] = useState(false);
  const[details,setDetails]=useState('')
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(()=>{
    fetchData()
  
  },[id])




  useEffect(()=>{
    if(isOpen?.type==="detailtoast"){
      handleSuccess(isOpen?.msg,language)
    }
    else if(isOpen?.type==="detailError"){
      handleClick(isOpen?.msg,language)
    }
    else if(isOpen==="detailpriceError"){
      handleClick("Price is required",language)
    }
    else if(isOpen?.type==="toast"){
      handleSuccess(isOpen?.msg,language)
    }
    else if(isOpen?.type==="share"){
      handleSuccess(isOpen?.msg,language)
    }
    else if(isOpen?.type==="toastError"){
      handleClick(isOpen?.msg,language)
    }
    else if(isOpen==="priceError"){
      handleClick("Price is required",language)
    }
  },[isOpen])


  const fetchData = async () => {
    console.log("IDDD",id,apiRoutes.get_projectDetails)
    setLoading(true)
    try {
      const response = await getRequest(`${apiRoutes.get_mosques_details}?link=${id}`); // Replace with your endpoint
if(response.status==="success")
   { 
    setLoading(false)
    setData(response?.data?.mosque)
    if(response?.data?.mosque?.cart_item)
   { setPrice(response?.data?.mosque?.cart_item?.price)}
    setSimilarData(response?.data?.similarMosques)
    console.log("Results",response)
  }
  else{
    setLoading(false)
  }
    } catch (error) {
      setLoading(false)
      console.error('Error fetching data:', error);
    }
  };


  const handleSubmit = async (item) => {
    // Prevent multiple submissions
  if (addloading) return; 
  // Prevent multiple submissions
  const data = {
    cart_type:"CART",
    is_edit:item?.cart_item?true:false,
    items: [
        {
            id:item?.id,
            type:"MOSQUE",
            price: price,
            tag_id:selectedTags
        }
    ]}

    // const data = {
    //   itemable_type: "project",
    // itemable_id: item?.id,
    //   price: price  
    // };
    setAddLoading(true)
  if(price){
    try {
      const response = await postRequest(apiRoutes.add_cart, data); // Replace with your endpoint
    
   
      if (response.status === "success") { 
        setAddLoading(false)
        let error = '';
        if (
          response?.message?.includes(ValidationErrors.addCartItem.added)
        ) {
          error = GetAppText('added_cart');
         
        } 
        else if (
          response?.message?.includes(ValidationErrors.addCartItem.updated)
        ) {
          error = GetAppText('updated_cart');
         
        } 
        dispatch(modalHandler({ type: "detailtoast", msg: error}));
        setTimeout(()=>{
            dispatch(modalHandler(""));
          },[500])
  
          // setPrice("");
   
      }
      else {
        setAddLoading(false)
    
        let error=""
        if (
          response?.data?.message?.includes(ValidationErrors.addCartItem.limit_error)
        ) {
          error = GetAppText('limit_price_cart');
     
        } 
        else {
          error = GetAppText('something_went_wrong');
        }


        dispatch(modalHandler({ type: "detailError", msg:error}));
        setTimeout(()=>{
            dispatch(modalHandler(""));
          },[500])
          // setPrice("");
       
      }
    } 
    catch (error) {
      console.error('Error fetching data:', error);
    } }
    else{
        setAddLoading(false)
     
        dispatch(modalHandler("detailpriceError"));
        setTimeout(()=>{
            dispatch(modalHandler(""));
          },[500])
  
    }
  
  };
  
  const handleDonate = async (item) => {
    // Prevent multiple submissions
  if (donateloading) return; 
  // Prevent multiple submissions
  
    // const data = {
    //   itemable_type: "project",
    // itemable_id: item?.id,
    //   price: price  
    // };
    const data = {
      cart_type:"DONATION",
      is_edit:item?.cart_item?true:false,
      items: [
          {
              id:item?.id,
              type:"MOSQUE",
              price: price,
              tag_id:selectedTags

          }
      ]}
    setDonateLoading(true)
  if(price){
    try {
      const response = await postRequest(apiRoutes.add_cart, data); // Replace with your endpoint
      console.log("Response", response);
   
      if (response.status === "success") { 
        setDonateLoading(false)
   
        // dispatch(modalHandler({ type: "detailtoast", msg: response?.message}));
        setTimeout(()=>{
            dispatch(modalHandler(""));
          },[500])
  
          setPrice("");
          navigate(`/checkout/donation`);
      } else {
        setDonateLoading(false)
    
        let error=""
        if (
          response?.data?.message?.includes(ValidationErrors.addCartItem.limit_error)
        ) {
          error = GetAppText('limit_price_cart');
     
        } 
        else {
          error = GetAppText('something_went_wrong');
        }

        dispatch(modalHandler({ type: "detailError", msg: error}));
        setTimeout(()=>{
            dispatch(modalHandler(""));
          },[500])
          setPrice("");
       
      }
    } 
    catch (error) {
      console.error('Error fetching data:', error);
    } }
    else{
      setDonateLoading(false)
     
        dispatch(modalHandler("detailpriceError"));
        setTimeout(()=>{
            dispatch(modalHandler(""));
          },[500])
  
    }
  
  };

      const handleProjectClick = (val) => {
      
        setDetails(val)
        setIsAddModal(true)
      dispatch(modalHandler("isDonate"));
        // navigate(`/donation-project/${id}`);
      };

      const ImageClick = (val) => {
   
        navigate(`/donation-mosque/${val?.slug}`);
      };

      const handleCartDetails=(items)=>{
        console.log("Data",items)
        setDetails(items)
        setIsAddModal(true)
        dispatch(modalHandler(""));
        
        }

const [selectedTags,setSelectedTags]=useState(3)

const handleTags=(val)=>{

setSelectedTags(val?.id)

}

        const handle_share=()=>{
          const currentURL = window.location.href;
       
        const fullLink=`${currentURL}`
            // Copy the link to the clipboard
            navigator.clipboard.writeText(fullLink).then(() => {
              dispatch(modalHandler({type:"share",msg:GetAppText("link")}));
              setTimeout(()=>{
                dispatch(modalHandler(""));
              },100)
            });
        
        }
        



  return (
    <>
   <ToastContainer />
    <div className="md:px-20 px-5 py-16 w-full space-y-16">
         
{  loading?    <div className="flex justify-center items-center h-[540px]">
      <Loader/>
      </div>:
      <div
        className={`md:flex ${
          language === "_ar" ? " " : "flex-row-reverse md:space-x-reverse "
        } md:space-x-10 md:space-y-0 space-y-8  w-full justify-between`}
      >
<div className="md:w-[65%]">
<img 

src={`${Image_URL}${data?.image}`}
className={`object-cover  rounded-lg  md:h-[650px] w-full h-[460px]`} alt="logo" />
        {/* {
          data?.media?.map((item)=>(
            <img src={item?.thumbnail} className={`object-cover  ${data?.media?.length===1?"rounded-lg  h-[725px]":""}`} alt="logo" />
          ))
        } */}
</div>

        <div className="w-full text-primary space-y-6">
        <div
            className={`flex sm:space-y-0 space-y-3 space-y-reverse ${
              language === "_ar" ? "sm:flex-row-reverse  items-end" : ""
            } sm:items-center sm:justify-between flex-col-reverse `}
          >
            <h1 className="font-extrabold md:text-F24 text-F22 ">{ language === "_ar" ?data?.title_ar :data?.title}</h1>
            <ButtonComponent
             onClick={()=>{handle_share()}}
             tooltip={GetAppText("link_share")}
              title={GetAppText("sharing")}
              svg={
                <SvgComponent
                  name={"ShareSvg"}
                  className={"w-4 h-4"}
                  stroke={"black"}
                  fill={"none"}
                />
              }
              styles={"!py-2 !px-3 !text-black !w-fit  !text-F16"}
              type="secondary_transparent"
            />
          </div>
          <div className={`font-normal ${language === "_ar" ?"text-right":"text-left"}  md:text-F16 text-F14`}>{language === "_ar" ?data?.description_ar :data?.description}</div>
     
          <SeparatorComponent className={"!border-borderSecondaryColor !my-10"} />
          <div
            className={`sm:flex sm:space-y-0 space-y-3  ${
              language === "_ar" ? "flex-row-reverse  sm:space-x-reverse" : ""
            } sm:space-x-3`}
          >
           
              <div  className="bg-btn_gray w-full rounded-md p-4 md:space-y-3 space-y-2">
                <div
                  className={` flex items-center ${
                    language === "_ar"
                      ? "flex-row-reverse  space-x-reverse"
                      : ""
                  } space-x-2`}
                >
                  <SvgComponent
                    name={"Clock"}
                    className={"w-5 h-5"}
                    stroke={"#999999"}
                    fill={"none"}
                  />
                  <h2 className={`text-secondaryTextColor ${
                    language === "_ar"
                      ? "text-right"
                      : ""
                  } text-F14 font-semibold `}>
                    {GetAppText("remain_time")}
                  </h2>
                </div>
                <h2
                  className={`flex items-center ${
                    language === "_ar"
                      ? "flex-row-reverse  space-x-reverse"
                      : ""
                  } text-black text-F14 font-bold `}
                >
                  {data?.days_left} {GetAppText("day")}
                </h2>
              </div>
              <div  className="bg-btn_gray w-full rounded-md p-4 md:space-y-3 space-y-2">
                <div
                  className={` flex  items-center ${
                    language === "_ar"
                      ? "flex-row-reverse  space-x-reverse"
                      : ""
                  } space-x-2`}
                >
                  <SvgComponent
                    name={"Block"}
                    className={"w-5 h-5"}
                    stroke={"#999999"}
                    fill={"none"}
                  />
                  <h2 className={`text-secondaryTextColor ${
                    language === "_ar"
                      ? "text-right"
                      : ""
                  }  text-F14 font-semibold `}>
                    {GetAppText("domain")}{" "}
                  </h2>
                </div>
                <h2
                  className={`flex items-center ${
                    language === "_ar"
                      ? "flex-row-reverse  space-x-reverse"
                      : ""
                  } text-black text-F14 font-bold `}
                >
                  {data?.category? language === "_ar"?data?.category?.title_ar:data?.category?.title:'N/A'}
                </h2>
              </div>
              <div  className="bg-btn_gray w-full rounded-md p-4 md:space-y-3 space-y-2">
                <div
                  className={` flex items-center ${
                    language === "_ar"
                      ? "flex-row-reverse  space-x-reverse"
                      : ""
                  } space-x-2`}
                >
                  <SvgComponent
                    name={"Donation"}
                    className={"w-5 h-5"}
                    stroke={"#999999"}
                    fill={"none"}
                  />
                  <h2 className={`text-secondaryTextColor ${
                    language === "_ar"
                      ? "text-right"
                      : ""
                  }  text-F14 font-semibold `}>
                    {GetAppText("max_limit")}{" "}
                  </h2>
                </div>
                <h2
                  className={`flex items-center ${
                    language === "_ar"
                      ? "flex-row-reverse  space-x-reverse"
                      : ""
                  } text-black text-F14 font-bold `}
                >
                  {settingData?.data[18]?.value?.toLocaleString()}  {GetAppText("bhd")}
                </h2>
              </div>
           
          </div>
          <div className="pt-4 space-y-4">
          <h1 className={`font-semibold flex  ${language === "_ar" ?"justify-end":""}  text-F16`} >{GetAppText("select_project")}</h1>

          <div className={`flex-wrap  ${language === "_ar" ?"justify-end":""}  flex gap-3`}>
{data?.tags?.length>0 && 
data?.tags?.map((item)=>(
<div onClick={()=>{handleTags(item)}} className={`w-[144px] h-[48px] ${selectedTags===item?.id?'bg-primary text-white cursor-auto':'cursor-pointer text-secondaryTextColor'} flex items-center justify-center 
  text-F16 font-medium border border-secondaryTextColor rounded-[4px]  `}>
{language === "_ar" ?item.title_ar:item.title}
</div>
))
}
</div>
</div>
          <SeparatorComponent className={"!border-borderSecondaryColor !my-10"} />
          {/* <ProgressBar value={data?.goal_percentage} progressStyle={"!p-1.5"} /> */}
          {/* <div className="flex justify-between items-center pb-2">
            <div>
              <h2 className="text-black text-F14 font-bold ">
                {GetAppText("goal")}
              </h2>
              <h2 className="text-secondaryTextColor text-F14 font-semibold ">
              {data?.goal_amount?.toLocaleString()} {GetAppText("bhd")}
              </h2>
            </div>

            <div>
              <h2 className="text-black text-F14 font-bold ">
                {GetAppText("residual")}
              </h2>
              <h2 className="text-secondaryTextColor text-F14 font-semibold ">
              {(data?.remaining_goal_amount)?.toLocaleString()} {GetAppText("bhd")} 
              </h2>
            </div>
            <div>
              <h2 className="text-black text-F14 font-bold ">
                {GetAppText("donated")}{" "}
              </h2>
              <h2 className="text-secondaryTextColor text-F14 font-semibold ">
                {data?.donated_amount?.toLocaleString()} {GetAppText("bhd")}
              </h2>
            </div>
          </div> */}
          <div className={`flex sm:flex-row flex-col-reverse   ${language === "_ar" ? "flex-row-reverse sm:space-x-reverse" : ""} sm:space-y-0 space-y-reverse space-y-4  sm:space-x-6 pt-2`}>
          <div className=" lg:w-[55%] md:w-[75%] w-full">
            <InputComponent type="number" title="donation_amount"
            value={price}  onChange={(e) => setPrice(e.target.value)}
            />
            </div>
            <div className="flex  items-center gap-2  lg:w-[45%] md:w-[35%] sm:w-[60%] lg:flex-nowrap md:flex-wrap flex-nowrap">
            <ButtonComponent
            onClick={()=>{handleSubmit(data)}}
            loading={addloading}
            disabled={donateloading ||addloading}
              styles={"!text-F18 !w-full  !px-1 !py-2"}
              type="primary"
              title={GetAppText("add_cart")}
            />
            <ButtonComponent
            onClick={()=>{handleDonate(data)}}
            loading={donateloading}
            disabled={donateloading || addloading}
              isSvg
              styles={"!text-F18 !px-1 !w-full !py-2  "}
              title={GetAppText("donate_now")}
            />
            </div>
           
          </div>
        </div>
      </div>
      }
      <div>
        <SeparatorComponent  />
        <div className={"space-y-8 pt-4"}>
          <h1
            className={`font-bold text-F27 text-primary flex ${
              language === "_ar" ? "flex-row-reverse" : ""
            } `}
          >
            {GetAppText("similar_projects")}
          </h1>
          <div
            className={`flex gap-5 flex-wrap md:justify-normal justify-center ${language === "_ar" ? "flex-row-reverse" : ""} `}
          >
         {loading?    <div className="flex justify-center items-center h-[440px]">
      <Loader/>
      </div>:similarData.length>0 ?
      similarData?.map((item,index)=>(
           <DonationCard  data={item} onClick={()=>{handleProjectClick(item)}} onImageClick={()=>{ImageClick(item)}}  onCartClick={()=>{handleCartDetails(item)}}   key={index}/>
         )):
         (!loading && <div className="flex w-full h-[350px]  justify-center text-F20 font-semibold items-center">
          {GetAppText("no_data")}
           </div>)
           }
          </div>
        </div>

      </div>
     

    </div>
  
    </>
  );
};

export default MosqueDetails;

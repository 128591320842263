import React, { useState } from "react";
import { GetAppText } from "../../../../utils";
import { useSelector } from "react-redux";

import { ReactComponent as Message } from "../../../../assets/svgs/message.svg";
import { ReactComponent as Phone } from "../../../../assets/svgs/phoneCircle.svg";
import { ReactComponent as Whatsapp } from "../../../../assets/svgs/whatsapp.svg";
import { ButtonComponent } from "../../../components/ButtonComponent";
import { GoogleMap, useJsApiLoader,MarkerF,DirectionsRenderer } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '340px',
  borderRadius:"12px"
};

const center = {
  lat:26.211034409555996,
  lng: 50.57099908443672
};

const Contact_us = ({data}) => {
  const markerPosition = { lat:26.211034409555996,
    lng: 50.57099908443672}; 

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBvvRJkCY7OYQo5QGhSzqHl0FCC5uYRUQE"
  })

  const [loading, setLoading] = useState(false);
  // const [map, setMap] = useState(null)

  // const onLoad = React.useCallback(function callback(map) {
  //   // This is just an example of getting and using the map instance!!! don't just blindly copy!
  //   const bounds = new window.google.maps.LatLngBounds(center);
  //   map.fitBounds(bounds);

  //   setMap(map)
  // }, [])

  // const onUnmount = React.useCallback(function callback(map) {
  //   setMap(null)
  // }, [])

  const language = useSelector((state) => state.language.value);

  const settingData = useSelector((state) => state?.setting?.value);
// const data =[
//   {id:1,name:"example@example.com",svgName:<Message/>},
//   {id:2,name:"+973 1700 0000",svgName:<Phone/>},
//   {id:3,name:"+973 3600 0000",svgName:<Whatsapp/>},
// ]


  const mapOptions = {
    mapTypeControl: false, // Disable the map and satellite options
    fullscreenControl: false, // Optionally disable fullscreen control
    streetViewControl: false, // Optionally disable Street View control
  };

  const [directions, setDirections] = useState(null);

  const getDirections = () => {
  
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLoading(true); // Start loading
        const origin = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        const directionsService = new window.google.maps.DirectionsService();

        // Simulate a delay for loading demonstration
       
          directionsService.route(
            {
              origin: origin,
              destination: markerPosition,
              travelMode: window.google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                setDirections(result);
              } else {
                console.error(`Error fetching directions ${result}`);
              }
              setLoading(false); // Stop loading
            }
          );
     
      });
    }
  };

  const whatsAppInfo = settingData?.data?.find(
    item => item.name === 'WHATSAPP',
  );
  const webEmail = settingData?.data?.find(
    item => item.name === 'WEBSITE_EMAIL',
  );
  const webPhone = settingData?.data?.find(
    item => item.name === 'WEBSITE_PHONE_NUMBER',
  );
  const location = settingData?.data?.find(
    item => item.name === 'OFFICE_LOCATION',
  );


  return (
    <div className={`w-full`}>
<div className="sm:space-y-6 space-y-4">
<h2
            className={`flex items-center ${language === "_ar"
                ? "flex-row-reverse  space-x-reverse"
                : ""
              } text-primary text-F24 font-bold `}
          >
            {GetAppText("contact_us")}
          </h2>
          <div className="space-y-6">
          <div className="w-full bg-offWhite p-6 rounded-xl space-y-5 ">
          <h2
            className={`flex items-center ${language === "_ar"
                ? "flex-row-reverse  space-x-reverse"
                : ""
              } text-secondaryTextColor text-F18 font-semibold `}
          >
            {GetAppText("contact_us")}
          </h2>
          <div  className={`flex flex-row lg:flex-nowrap flex-wrap  gap-6 items-center justify-between ${language === "_ar"
                ? ""
                : "-flex-row-reverse  space-x-reverse"
              } `}>
    
           <div className={`flex w-full text-[#303036] text-F16 space-x-2 border border-borderColor p-3 rounded-lg items-center 
           `}>
            <div>
            <Message/>
</div>
<div>
{webEmail.value}
</div>


      </div>
      <div className={`flex  w-full text-[#303036] text-F16 space-x-2 border border-borderColor p-3 rounded-lg items-center 
           `}>
            <div>
            <Phone/>
</div>
<div>
{webPhone.value}
</div>


      </div>
      <div className={`flex   w-full text-[#303036] text-F16 space-x-2 border border-borderColor p-3 rounded-lg items-center 
           `}>
            <div>
            <Whatsapp/>
</div>
<div>
{whatsAppInfo.value}
</div>


      </div>
        </div>
            </div>
                 <div className="w-full bg-offWhite p-6 rounded-xl space-y-6">
          <h2
            className={`flex items-center ${language === "_ar"
                ? "flex-row-reverse  space-x-reverse"
                : ""
              } text-secondaryTextColor text-F18 font-semibold `}
          >
            {GetAppText("our_website")}
          </h2>
          <div style={{ height: '340px', width: '100%' }}>
      
    {  isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={18}
        // onLoad={onLoad}
        options={mapOptions}
        // onUnmount={onUnmount}
      >
         <MarkerF position={{lat:26.211034409555996,
  lng: 50.57099908443672}} />
    {directions && <DirectionsRenderer directions={directions} />}
        { /* Child components, such as markers, info windows, etc. */ }
        <></>
      </GoogleMap>
  ) : <></>}
    </div>


       <div   className={`sm:flex sm:space-y-0 space-y-3  items-center pt-1 justify-between ${language === "_ar"
                ? "flex-row-reverse  space-x-reverse"
                : ""
              }  `}>
       <h2
            className={`flex items-center ${language === "_ar"
                ? "flex-row-reverse text-right  space-x-reverse"
                : ""
              } text-black text-F16 font-medium `}
          >
              {language === "_ar"?location?.value_ar:location?.value}
          
            {/* {GetAppText("address")} */}
          </h2>
          <ButtonComponent  onClick={getDirections} styles={"!px-6 sm:!w-fit w-full !text-F18"} title={loading ? GetAppText('calculating') : GetAppText('get_direction')} type="secondary_transparent"/>
       </div>
            </div>
        
           
          </div>
</div>


    </div>
  );
};

export default Contact_us;

import React, { useEffect } from "react";
import { GetAppText } from "../../../../utils";
import { useSelector } from "react-redux";
import { NewsCardComponent } from "../../../components/NewsCardComponent";
import { Loader } from "../../../components/Loader";
import { useNavigate } from 'react-router-dom';
export const HomeNews = ({data,loading}) => {
  const navigate = useNavigate();
  useEffect(()=>{
console.log("DATAAAAAAAAAA",data?.length)

  },[])
//   const fetchData = async () => {
//     setLoading(true)
//     try {
//       const response = await getRequest(apiRoutes.get_news); // Replace with your endpoint
// if(response.success)
//    { 
//     setLoading(false)
//     setData(response?.data?.news)
//     console.log("Results",response)
//   }
//   else{
//     setLoading(false)
//   }
//     } catch (error) {
//       setLoading(false)
//       console.error('Error fetching data:', error);
//     }
//   };
  const handleProjectClick = (id) => {
    console.log("ID",id)
    navigate(`/news/${id}`);
  };
  const language = useSelector((state) => state.language.value);
  return (
    <div className="w-full h-full">
      <div>
        <h1
          className={`text-primary text-F32 font-semibold ${
            language === "_ar" ? "text-right" : "text-left"
          }`}
        >
          {GetAppText("news")}
        </h1>
        <div className="pt-8">
          <div className={`flex flex-wrap lg:gap-6 gap-4 ${
            language === "_ar" ? "flex-row-reverse " : ""
          } items-center md:justify-start justify-center`}>

{ loading ?
         <div className="flex h-[420px] w-full justify-center items-center">
         <Loader/>
         </div>
         : 
              data?.length>0 ? 
          data?.map((item,index)=>
(<NewsCardComponent  onClick={()=>{handleProjectClick(item?.slug)}} key={index}  data={item}/>))  
:
(!loading && <div className="flex w-full h-[300px] justify-center text-F20 font-semibold items-center">
          {GetAppText("no_data")}
              </div>)}

        
          </div>
        </div>
      </div>
    </div>
  );
};

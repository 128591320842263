import React, { useEffect, useState } from "react";
import { ButtonComponent } from "../../components/ButtonComponent";
import { GetAppText, ValidationErrors } from "../../../utils";
import { useSelector,useDispatch } from "react-redux";
import { SeparatorComponent } from "../../components/Separator";
import SvgComponent from "../../components/SvgComponent";

import { useNavigate } from 'react-router-dom';
import { apiRoutes } from "../../../apis/routes";
import { deleteRequest, getRequest, postRequest } from "../../../apis/methods";
import { ModalComponent } from "../../components/Modal";
import {ReactComponent as Texture} from "../../../assets/svgs/texture5.svg"
import { Loader } from "../../components/Loader";
import DonationModal from "../../components/DonationModal";
import { handleClick, handleSuccess } from '../../components/Toast/toastHelper';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Login } from "../Login";

import Signup from "../Signup";
import { modalHandler } from "../../../store/slices/modalSlice";
import { Image_URL } from "../../../utils/BaseUrl";
const DonarsBag = () => {
  const navigate = useNavigate();
  const dispatch=useDispatch()
  const language = useSelector((state) => state.language.value);
  const isOpen = useSelector((state) => state.openModel.value);
  const [loading,setLoading]=useState(false)
  const [Delete_loading,setDeleteLoading]=useState(false)
  const [Delete_data,setDeleteData]=useState(false)
  const [deleteModal,setDeleteModal]=useState(false)
  const[token,setToken]=useState("")
  const[details,setDetails]=useState('')
const[data,setData]=useState('')
const [isAddModal, setIsAddModal] = useState(false);
const [isloginModal, setIsloginModal] = useState(false);
const [isSignupnModal, setIsSignupModal] = useState(false);
const [totalCount,setTotalCount]=useState('')
// const [isOtpModal, setIsOtpModal] = useState(false);

useEffect(()=>{
  fetchData()
  const storedValue = localStorage.getItem("token");
  setToken(storedValue)
},[])

useEffect(()=>{
  if(isOpen?.type==="toast"){
    fetchData()
    handleSuccess(isOpen?.msg,language)
  }
  else if(isOpen?.type==="toastError"){
    fetchData()
    handleClick(isOpen?.msg,language)
  }
  else if(isOpen==="priceError"){
    handleClick("Price is required",language)
  }
  else if(isOpen==="LoginModal")
    {  setIsloginModal(false)
      setIsSignupModal(false)
    
    }
      else if(isOpen==="SignupModal"){
        setIsloginModal(false)
        setIsSignupModal(true)
      
      }
      else if(isOpen==="OtpModal"){
        setIsloginModal(false)
        setIsSignupModal(false)
   
      }
      // else if(isOpen=="ResetModal"){
      //   setIsloginModal(false)
      //   setIsSignupModal(false)
      //   setIsOtpModal(false)
      //   setIsResetModal(true)
      // }
},[isOpen])




  // const people = [
  //   {
  //     id: 1,
  //     title: "بناء مدرسة في المناطق الريفية",
  //     img: img,
  //     price: "10.0",
  //     day: "12",

  //     details:
  //       "يهدف هذا المشروع إلى بناء مدرسة حديثة في المناطق الريفية لتوفير التعليم الجيد للأطفال الذين يعيشون في تلك المناطق. ستشمل المدرسة فصول دراسية مجهزة تجهيزًا كاملاً.",
  //   },
  //   {
  //     id: 2,
  //     title: "بناء مدرسة في المناطق الريفية",
  //     img: img2,
  //     price: "14.0",
  //     day: "8",

  //     details:
  //       "يهدف هذا المشروع إلى بناء مدرسة حديثة في المناطق الريفية لتوفير التعليم الجيد للأطفال الذين يعيشون في تلك المناطق. ستشمل المدرسة فصول دراسية مجهزة تجهيزًا كاملاً.",
  //   },
  // ];
  const handleLogin=()=>{
    // navigate(`/login`);
    setIsloginModal(true)
}
const handleSignup=()=>{
  // navigate(`/signup`);
  setIsSignupModal(true)
}
  const fetchData = async () => {
    console.log("Dataa")
    setLoading(true)
    try {
      const response = await getRequest(`${apiRoutes.get_cart}`
      
        ); // Replace with your endpoint
if(response.status === "success")
   { 
    setLoading(false)
    setData(response?.data?.cart?.items)
    setTotalCount(response?.data)
    console.log("Results",response)
  }
  else{
    setLoading(false)
  }
    } catch (error) {
      setLoading(false)
      console.error('Error fetching data:', error);
    }
  };
  const handleSubmit = async (item) => {
    // Prevent multiple submissions
  // console.log("DeleteData",Delete_data)
  
  if (Delete_loading) return; // Prevent multiple submissions
  
    // const data = {
    //   id: Delete_data?.id
 
    // };
    const data = { 
      cart_id: Delete_data?.cart_id,
    item_type: Delete_data?.itemable_type?.split('\\').pop().toUpperCase(),
    item_id: Delete_data?.itemable?.id}
    setDeleteLoading(true)
  
    // console.log("Delete",data)
    try {
      const response = await postRequest(apiRoutes.delete_cart, data); // Replace with your endpoint
      console.log("Response", response);
 
      if (response.status === "success") { 
        fetchData()
        setDeleteLoading(false)

        let error = '';
        if (
          response?.message?.includes(ValidationErrors.addCartItem.remove)
        ) {
          error = GetAppText('remove_cart');
        
        } 
        
        dispatch(modalHandler({ type: "toast", msg: error}));
        setTimeout(()=>{
            dispatch(modalHandler(""));
          },[500])
  
   
        setDeleteModal(false)
      } else {
        setDeleteModal(false)
        let error=""
        if (
          response?.data?.message?.includes(ValidationErrors.addCartItem.limit_error)
        ) {
          error = GetAppText('limit_price_cart');
     
        } 
        else {
          error = GetAppText('something_went_wrong');
        }
        if(error)
       { dispatch(modalHandler({ type: "toastError", msg: error}));
        setTimeout(()=>{
            dispatch(modalHandler(""));
          },[500])}
          else{
            dispatch(modalHandler({ type: "toastError", msg: response?.data?.message}));
        setTimeout(()=>{
            dispatch(modalHandler(""));
          },[500])
          }
        setDeleteLoading(false)
  
       
      }
    } catch (error) {
      setDeleteLoading(false)
      console.error('Error fetching data:', error);
    } 
  
  };
  
const handleEdit=(item)=>{
  setIsAddModal(true)
  setDetails(item)
}

  return (
    <>
    <div className="md:px-20 px-5 py-10 md:py-16 w-full space-y-6">
    <ToastContainer />
      <div
        className={`w-full flex sm:space-y-0 space-y-3 sm:flex-row flex-col sm:items-center ${
          language === "_ar" ? "sm:flex-row-reverse items-end" : "items-start"
        }  sm:justify-between `}
      >
        <div
          className={`flex ${
            language === "_ar" ? "" : "flex-row-reverse space-x-reverse"
          }     items-center space-x-2`}
        >
       {totalCount?.count>0&&  <ButtonComponent
            title={totalCount?.count}
            styles={"!py-0.5 sm:!px-3 !px-2 whitespace-nowrap   sm:text-F20 text-F16"}
            type="primary"
          />}
          <h2
            className={`flex items-center ${
              language === "_ar" ? "flex-row-reverse  space-x-reverse" : ""
            } text-primary text-F24 font-bold `}
          >
            {GetAppText("donation_basket")}
          </h2>
        </div>
     { !token &&  <div
          className={`flex  items-center space-x-3 sm:w-fit w-full  ${
            language === "_ar" ? "flex-row-reverse space-x-reverse" : "flex-row"
          }    `}
        >
          <ButtonComponent
            title={GetAppText("create_account")}
            styles={"py-2 px-4 whitespace-nowrap sm:!w-fit !w-full text-F16"}
            type="primary"
            onClick={handleSignup}
          />
          <ButtonComponent
            title={GetAppText("login")}
            styles={"py-2 px-4 whitespace-nowrap sm:!w-fit !w-full text-F16 "}
            type="secondary_transparent"
            onClick={handleLogin}
          />
        </div>}
      </div>
      <div className="mt-8 flow-root rounded-[12px] bg-white sm:px-6 px-3  py-6">
        {/* Table */}
        {loading?  <div className="flex h-[280px] w-full justify-center items-center">
 <Loader/>
 </div>:<>
        <div className="inline-block min-w-full align-middle  max-h-96 overflow-y-auto relative">
          
          <table
            dir={language === "_ar" ? "rtl" : ""}
            className="min-w-full w-full divide-y  divide-borderSecondaryColor "
          >
            <thead className="sticky bg-white top-0 z-10 divide-y mb-2  divide-borderSecondaryColor">
              <tr className="">
                <th
                  scope="col"
                  className={` pb-3 ${
                    language === "_ar" ? "text-right" : "text-left"
                  } sm:w-96    sm:text-F16 text-F13 font-normal text-secondaryTextColor sm:pl-0`}
                >
                  {GetAppText("project")}
                </th>
                <th
                  scope="col"
                  className={`px-3 pb-3 ${
                    language === "_ar" ? "text-right" : "text-left"
                  } sm:w-48 w-20 sm:text-F16 text-F13 font-normal text-secondaryTextColor `}
                >
                  {GetAppText("donation_value")}
                </th>
                <th
                  scope="col"
                  className={`px-3 pb-3 ${
                    language === "_ar" ? "text-right" : "text-left"
                  } sm:w-40  w-20  sm:text-F16 text-F13 font-normal text-secondaryTextColor `}
                >
                  {GetAppText("remain_time")}
                </th>
                <th
                  scope="col"
                  className={`pb-3 ${
                    language === "_ar" ? "text-right" : "text-left"
                  } sm:w-40  text-F16 font-normal text-secondaryTextColor `}
                ></th>
              </tr>
            </thead>
           {data?.length>0? 
           <tbody >
              {data&&data?.map((person) => (
                <tr key={person.id} className="">
                  <td
                    className={`py-5   text-sm  ${
                      language === "_ar" ? "text-right pl-10" : "text-left pr-10"
                    } text-gray-500 `}
                  >
                    <div
                      className={`flex flex-row-reverse gap-2  sm:items-center justify-end ${person?.itemable_type?.split('\\').pop() === 'Project' || person?.itemable_type?.split('\\').pop() === 'Mosque'?' sm:space-x-5':'sm:space-x-5'}  ${
                        language === "_ar" ? " space-x-reverse" : " "
                      }`}
                    >
                      <div className="space-y-2">
                        <div className="sm:text-F16 text-F13 text-black font-bold   line-clamp-1">
                          
                          { language === "_ar" ? person?.itemable?.title_ar: person?.itemable?.title}
                        </div>
                        <div className="sm:text-F12 text-F10 text-primary md:line-clamp-3 line-clamp-1 font-normal">
                        { language === "_ar" ? person?.itemable?.description_ar: person?.itemable?.description}
                        </div>
                      </div>
                      
                      <img
                        alt="Snap"
                        src={`${Image_URL}${person?.itemable?.image}`}
                        className={`${person?.itemable_type?.split('\\').pop() === 'Project' || person?.itemable_type?.split('\\').pop() === 'Mosque'?`sm:w-[100px] sm:h-[100px]  w-[40px]  h-[40px]`:`sm:w-[50px] w-[30px]  `} object-cover rounded-lg `}
                      />
                    </div>
                  </td>
                  <td
                    className={` py-5 text-black  sm:text-F16 text-F12 font-bold  ${
                      language === "_ar" ? "text-right" : "text-left"
                    } `}
                  >
                    <div
                    onClick={()=>{handleEdit(person)}}
                      className={`flex sm:px-3  cursor-pointer hover:underline items-center ${
                        language === "_ar"
                          ? "space-x-2 space-x-reverse "
                          : "justify-start space-x-2"
                      }`}
                    >
                      <div>
                        {person?.price?.toLocaleString()} {GetAppText("bhd")}
                      </div>
                      <SvgComponent
                        name={"Edit"}
                        className={"sm:w-5 sm:h-5 w-3.5 h-3.5 "}
                        fill="none"
                        stroke={"black"}
                      />
                    </div>
                  </td>
                  <td
                    className={` py-5   sm:text-F16  text-F12 ${
                      language === "_ar" ? "text-right" : "text-left"
                    } text-secondaryTextColor`}
                  >
                  { person?.itemable?.days_left? <div
                      className={`flex px-3 font-semibold items-center ${
                        language === "_ar"
                          ? " space-x-reverse "
                          : " justify-start "
                      } space-x-1 `}
                    >
                      <SvgComponent
                        name={"Clock"}
                        className={"sm:w-5 sm:h-5 w-3 h-3"}
                        fill="none"
                        stroke={"#999999"}
                      />

                      <div>
                        {person?.itemable?.days_left} {GetAppText("day")}
                      </div>
                    </div>:
                    <div className="flex justify-center text-F18">
                      -
                      </div>}
                  </td>
                  <td
                    className={` py-5  pr-3 text-sm  ${
                      language === "_ar" ? "text-right" : "text-left"
                    } text-gray-500 `}
                  >
                    <div className="flex items-center justify-center">
                      <div onClick={()=>{setDeleteModal(true);setDeleteData(person)}} className="sm:p-3.5 p-2.5 rounded-md cursor-pointer bg-[#FFF4F4]">
                        <SvgComponent
                          name={"Cross"}
                          className={"w-2.5 h-2.5"}
                          fill="none"
                          stroke={"#E94747"}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              ))
              
              }
            </tbody>:
              <tbody>
              <tr>
                <td colSpan="4" className="py-32 text-secondaryTextColor  text-center text-F18 font-semibold">
                  {GetAppText("no_data")}
                </td>
              </tr>
            </tbody>}
          </table>
        </div>

    {data?.length>0 &&    <SeparatorComponent />}
        <div>
        { data?.length>0 &&  <div
            className={`w-full flex flex-col-reverse sm:space-y-0 space-y-3 space-y-reverse  sm:items-center sm:justify-between ${
              language === "_ar" ? "sm:flex-row" : "sm:flex-row-reverse sm:space-x-reverse justify-end"
            }  `}
          >
            <ButtonComponent
            onClick={()=>{navigate('/checkout/cart')}}
              title={GetAppText("complete_donation")}
              styles={"py-2.5 px-7 whitespace-nowrap   text-F16 "}
              type="secondary"
            />

            <div
              className={`flex flex-wrap ${
                language === "_ar" ? " justify-end" : "flex-row-reverse space-x-reverse"
              }     items-center space-x-2`}
            >
              <h2
                className={`flex items-center ${
                  language === "_ar" ? "flex-row-reverse space-x-reverse" : ""
                } text-primary sm:text-F24 text-F20 font-bold  space-x-1`}
              >
                <div>{totalCount?.total ? totalCount?.total?.toLocaleString() :"0.00" }</div> <div>{GetAppText("bhd")}</div>
              </h2>
              <h2
                className={`flex items-center ${
                  language === "_ar" ? "flex-row-reverse  space-x-reverse" : ""
                } text-secondaryTextColor sm:text-F18 text-F16  font-semibold  `}
              >
                {GetAppText("total")}
              </h2>
            </div>
          </div>}


        </div>
        </>
              }
      </div>
  
    </div>
  {isAddModal&&  <DonationModal isAddModal={isAddModal} details={details} setIsAddModal={setIsAddModal}/>}
  <ModalComponent styles="!w-[460px]" isOpen={isloginModal} onClose={()=>{setIsloginModal(false);dispatch(modalHandler(""));}}>
    
    <Login className="" />
   
    </ModalComponent>
    <ModalComponent styles="!w-[720px]" isOpen={deleteModal} onClose={()=>{setDeleteModal(false);}}>
    
    {/* <Login className="" /> */}
    <div
        className={`bg-primary relative w-full rounded-lg p-7 pt-10 ${
          language === "_ar" ? "text-right" : "text-left"
        }`}
      >
         <div className="absolute z-50 top-0 right-0">
     <Texture className="object-cover"/>
      </div>
      <div className="flex flex-col items-center justify-center space-y-8">
        <div className="flex flex-col items-center justify-center space-y-3">
        <div className="rounded-full bg-white p-4">
        <SvgComponent
                      name={"Cross"}
                      className={"w-5 h-5"}
                      stroke={"#E94747"}
                      fill={"none"}
                    />
        </div>
        <div className={`text-white text-center font-bold text-F22`}>
          {GetAppText("delete_msg")}
        </div>
        </div>
        <div className="w-full"> 
            <div className="space-x-4 w-full flex items-center">
            <ButtonComponent disabled={Delete_loading}  loading={Delete_loading} styles={"!w-full !py-2.5 !bg-errorColor !border-errorColor"} onClick={handleSubmit} title={GetAppText("delete")}  />
              <ButtonComponent styles={"!w-full !py-2.5"} onClick={()=>{setDeleteModal(false)}} title={GetAppText("cancel")} type="transparent"/>

            </div>
          </div>
      </div>

    </div>
   
    </ModalComponent>

    <ModalComponent styles="!w-[900px]" isOpen={isSignupnModal} onClose={()=>{setIsSignupModal(false);dispatch(modalHandler(""));}}>
  
  <Signup className="" />
 
  </ModalComponent>
    </>
  );
};

export default DonarsBag;

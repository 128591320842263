import React from "react";
import { GetAppText } from "../../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { StyleClass } from "../../../../utils";
import SvgComponent from "../../../components/SvgComponent";
import { switchTabHandler } from "../../../../store/slices/sideBarSlice";

export const HelpSupport = ({toggleSideBar}) => {
  const language = useSelector((state) => state.language.value);
  const dispatch = useDispatch();

  const data = [
    {
      id: 7,
      name: GetAppText("frequently_asked_questions"),
      component: "",
      icon: "Question",
    },
    {
      id: 8,
      name: GetAppText("contact_us"),
      component: "",
      icon: "Phone",
      sub_heading: GetAppText("contact_desc"),
    },
  ];
  const handleSelectOption = (option) => {
    dispatch(switchTabHandler(option.id));
    toggleSideBar({ value: true, id: option.id });
  };
  return (
    <div className="w-full h-full">
      <h1
        className={`text-F18 text-primary ${
          language === "_ar" ? "text-right" : "text-left"
        }`}
      >
        {GetAppText("help_support")}
      </h1>
      <div className="pt-4 flex flex-col space-y-4">
        {data.map((item) => (
          <div
            className="p-4 bg-white rounded-md cursor-pointer"
            key={item.id}
            onClick={() => handleSelectOption(item)}
          >
            <div
              className={`flex justify-between items-center ${StyleClass()}`}
            >
              <div className={`flex items-center space-x-3 ${StyleClass()}`}>
                <SvgComponent
                  name={item.icon}
                  className={"w-5 h-5"}
                  stroke={"black"}
                  fill={"none"}
                />
                <div className="flex flex-col space-y-1">
                  <span
                    className={`text-black text-F16 ${
                      language === "_ar" ? "text-right" : "text-left"
                    } font-semibold`}
                  >
                    {item.name}
                  </span>
                  {item.sub_heading && (
                    <span className="text-secondaryTextColor text-F14 font-semibold">
                      {item.sub_heading}
                    </span>
                  )}
                </div>
              </div>
              <div>
                <SvgComponent
                  name={language === "_ar" ? "ChevronLeft" : "ChevronRight"}
                  className={"w-6 h-6"}
                  stroke={"black"}
                  fill={"none"}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

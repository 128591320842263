import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: null,
  };
  
  export const SettingSlice = createSlice({
    name: "setting",
    initialState,
    reducers: {
      settingHandler: (state, action) => {
  console.log("Setting",action.payload)
        state.value = action.payload;
      },
    },
  });
  
  export const { settingHandler } = SettingSlice.actions;
  
  export default SettingSlice.reducer;
  
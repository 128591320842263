import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: null,
};

export const DataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    dataHandler: (state, action) => {
      console.log("Option", action.payload);
      state.value = action.payload;
    },
  },
});

export const { dataHandler } = DataSlice.actions;

export default DataSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: null,
};

export const UserDetailSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    userHandler: (state, action) => {

      state.value = action.payload;
    },
  },
});

export const { userHandler } = UserDetailSlice.actions;

export default UserDetailSlice.reducer;

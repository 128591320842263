import React from "react";
import { GetAppText } from "../../../../utils";
import { useDispatch, useSelector } from "react-redux";
import SvgComponent from "../../../components/SvgComponent";
import { StyleClass } from "../../../../utils";
import { switchTabHandler } from "../../../../store/slices/sideBarSlice";

export const SunniEndowments = ({ toggleSideBar }) => {
  const language = useSelector((state) => state.language.value);
  const dispatch = useDispatch();

  const data = [
    {
      id: 4,
      name: GetAppText("about_sunni_endowments"),
      component: "",
      icon: "InfoCircle",
    },
    {
      id: 5,
      name: GetAppText("terms_use_conditions"),
      component: "",
      icon: "Document",
    },
    {
      id: 6,
      name: GetAppText("privacy_policy"),
      component: "",
      icon: "Terms",
    },
  ];

  const handleSelectOption = (option) => {
    dispatch(switchTabHandler(option.id));
    toggleSideBar({ value: true, id: option.id });
  };
  return (
    <div className="w-full h-full">
      <h1
        className={`text-F18 text-primary ${
          language === "_ar" ? "text-right" : "text-left"
        }`}
      >
        {GetAppText("sunni_endowments")}
      </h1>
      <div className="pt-4 flex flex-col space-y-4">
        {data.map((item) => (
          <div
            className="p-4 bg-white rounded-md cursor-pointer"
            key={item.id}
            onClick={() => handleSelectOption(item)}
          >
            <div
              className={`flex justify-between items-center ${StyleClass()}`}
            >
              <div className={`flex items-center space-x-3 ${StyleClass()}`}>
                <SvgComponent
                  name={item.icon}
                  className={"w-5 h-5"}
                  stroke={"black"}
                  fill={"none"}
                />
                <span className="text-black text-F16 font-semibold">
                  {item.name}
                </span>
              </div>
              <div>
                <SvgComponent
                  name={language === "_ar" ? "ChevronLeft" : "ChevronRight"}
                  className={"w-6 h-6"}
                  stroke={"black"}
                  fill={"none"}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

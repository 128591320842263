import React, { useEffect, useRef, useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import { GetAppText, ValidationErrors } from "../../../utils";
import { InputComponent } from "../../components/InputComponent";
import { ButtonComponent } from "../../components/ButtonComponent";


import { modalHandler } from "../../../store/slices/modalSlice";
import { userHandler } from "../../../store/slices/userDetailsSlice";
import { apiRoutes } from "../../../apis/routes";
import { postRequest } from "../../../apis/methods";
import { AlertComponent } from "../../components/AlertComponent";
import { useAuth } from "../../../context/authContext";
export const Otp = ({update=false,info={}}) => {
  const language = useSelector((state) => state.language.value);
    const Data = useSelector((state) => state.data.value);
    const { login } = useAuth(); 

  const dispatch = useDispatch();
  const [resendOtp, setResendOtp] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState("");

useEffect(()=>{
  console.log("Data",Data)
},[Data])
useEffect(() => {
    if (resendOtp) {
      const countdownInterval = setInterval(() => {
        setSeconds(prevSeconds => {
          if (prevSeconds === 0) {
            clearInterval(countdownInterval);
            setSeconds(60);
            setResendOtp(false);
            return 0;
          } else {
            return prevSeconds - 1;
          }
        });
      }, 1000);

      return () => {
        clearInterval(countdownInterval);
      };
    }
  }, [resendOtp]);
const [inputValues, setInputValues] = useState(["", "", "", ""]); // Assuming you have 4 inputs
const inputRefs = useRef([]);
const handleInputChange = (index, event) => {
  const newValues = [...inputValues];
  newValues[index] = event.target.value;
  setInputValues(newValues);

  // Move to the next input if the current one is filled
  if (event.target.value && index < inputRefs.current.length - 1) {
    inputRefs.current[index + 1].focus();
  }

};
useEffect(() => {
  if (inputRefs.current[0]) {
    inputRefs.current[0].focus();
  }
}, []);


const handleSubmit = async() => {
  const payload = inputValues.join(""); 
  console.log("INPUT",inputValues)
  // Concatenate OTP values
  const val=Number(payload)
  // timer
  // setResendOtp(true);
 
  const data={
    country_id: Data?.country_id,
    phone: Data?.phone,
    token: val
  }
  console.log('Payload:', typeof(val)); // Handle the payload (e.g., send it to a server)
  setLoading(true)
  if(payload.length === 4)
  {try {
    const response = await postRequest(apiRoutes.verify_otp,data); // Replace with your endpoint
    console.log("Results",response)
  if(response.status==="success")
  { 
  setLoading(false)
  dispatch(modalHandler({type:"initials",msg:""}));
  // setData(response?.data)
  setTimeout(()=>{
    dispatch(modalHandler(""));
  },100)
  // navigate("/");
  login(response?.data?.token)

  dispatch(userHandler(response?.data));
  }
  else{
    let error='';
    if (
      response?.data?.message.includes(ValidationErrors.Otp.notValid)
    ) {
      error = GetAppText('OtpVerify');
    }  else {
      error = GetAppText('something_went_wrong');
    }
    setLoading(false)
    setAlert(error)


  setTimeout(()=>{
    setAlert("")
  },[1500])
  }
  } catch (error) {
    setLoading(false)
    console.error('Error fetching data:', error);
  }
  }
  else{
    setLoading(false)
    setAlert("Please enter 4 digit OTP")
    setTimeout(()=>{
      setAlert("")
    },[1500])
  }
 
      // Here, you can send the payload to an API or perform any other action needed.
    };


    const handleUpdateNumber = async() => {
      const payload = inputValues.join(""); 
      console.log("INPUT",inputValues)
      // Concatenate OTP values
      const val=Number(payload)
      // timer
      // setResendOtp(true);
     console.log("Dataaa",Data)
      const data={
        country_id: info?.id,
        phone: `${info?.code}${info?.ph}`,
        token: val
      }
      console.log('Payload:', typeof(val)); // Handle the payload (e.g., send it to a server)
      setLoading(true)
      if(payload.length === 4)
      {try {
        const response = await postRequest(apiRoutes.update_phone,data); // Replace with your endpoint
        console.log("Results",response)
      if(response.status==="success")
      { 
      setLoading(false)
      dispatch(modalHandler({type:"updateOtpToast",msg:"Phone number updated successfully"}));
      // setData(response?.data)
      // dispatch(modalHandler("UpOtpModal"));
      setTimeout(()=>{
        dispatch(modalHandler(""));
      },100)
      // navigate("/");
      // login(response?.data?.token)
    
      // dispatch(userHandler(response?.data));
      }
      else{
    
      setLoading(false)
      setAlert(response?.data?.message)
      setTimeout(()=>{
        setAlert("")
      },[1500])
      }
      } catch (error) {
        setLoading(false)
        console.error('Error fetching data:', error);
      }
      }
      else{
        setLoading(false)
        setAlert("Please enter 4 digit OTP")
        setTimeout(()=>{
          setAlert("")
        },[1500])
      }
     
          // Here, you can send the payload to an API or perform any other action needed.
        };

        const handleResendOtp = async() => {
          if(!resendOtp)
       {   const payload = inputValues.join(""); 
          console.log("INPUT",inputValues)
          // Concatenate OTP values
          const val=Number(payload)
          // timer
          // setResendOtp(true);
         console.log("Dataaa",Data)
          const data={
            country_id: Data?.country_id,
            phone:Data?.phone,
          
          }
          console.log('Payload:', typeof(val)); // Handle the payload (e.g., send it to a server)
          // setLoading(true)
      
       
            try {
            const response = await postRequest(apiRoutes.resend_otp,data); // Replace with your endpoint
            console.log("Results",response)
          if(response.status==="success")
          { 
            setResendOtp(true)
          dispatch(modalHandler({type:"toast",msg:"OTP resend successfully"}));
          // setData(response?.data)
          // dispatch(modalHandler("UpOtpModal"));
          // setTimeout(()=>{
          //   dispatch(modalHandler(""));
          // },100)
          // navigate("/");
          // login(response?.data?.token)
        
          // dispatch(userHandler(response?.data));
          }
          else{
        
          setLoading(false)
          setAlert(response?.data?.message)
          setTimeout(()=>{
            setAlert("")
          },[1500])
          }
          
          } catch (error) {
            setLoading(false)
            console.error('Error fetching data:', error);
          }
   
        }
         
              // Here, you can send the payload to an API or perform any other action needed.
            };
    
            const handleResendOtpUser = async() => {
              if(!resendOtp)
           {   const payload = inputValues.join(""); 
              console.log("INPUT",inputValues)
              // Concatenate OTP values
              const val=Number(payload)
              // timer
              // setResendOtp(true);
             console.log("Dataaa",Data)
              const data={
                country_id: info?.id,
                  phone: `${info?.code}${info?.ph}`,
              
              }
              console.log('Payload:', typeof(val)); // Handle the payload (e.g., send it to a server)
              // setLoading(true)
          
           
                try {
                const response = await postRequest(apiRoutes.user_resend_otp,data); // Replace with your endpoint
                console.log("Results",response)
              if(response.status==="success")
              { 
                setResendOtp(true)
              dispatch(modalHandler({type:"updateToast",msg:"OTP resend successfully"}));
              // setData(response?.data)
              // dispatch(modalHandler("UpOtpModal"));
              // setTimeout(()=>{
              //   dispatch(modalHandler(""));
              // },100)
              // navigate("/");
              // login(response?.data?.token)
            
              // dispatch(userHandler(response?.data));
              }
              else{
            
              setLoading(false)
              setAlert(response?.data?.message)
              setTimeout(()=>{
                setAlert("")
              },[1500])
              }
              
              } catch (error) {
                setLoading(false)
                console.error('Error fetching data:', error);
              }
       
            }
             
                  // Here, you can send the payload to an API or perform any other action needed.
                };
        const handler=()=>{
          console.log("Updateeee",update)
          if(update==true){
         
            handleUpdateNumber()
          }else{
            // console.log("Loginnn")
            handleSubmit()
          }
        }


const otpResendHandler=()=>{
  if(update==true){
         
    handleResendOtpUser()
  }else{
    // console.log("Loginnn")
    handleResendOtp()
  }
}

    const handleKeyDown = (index, event) => {
      // Handle backspace to move to the previous input
      if (event.key === "Backspace" && !inputValues[index] && index > 0) {
        inputRefs.current[index - 1].focus();
      }
      else if (event.key === 'Enter') {
        handler(); // Call the handleSubmit function when Enter key is pressed
        }
      
    };

const formattedTime = `${Math.floor(seconds / 60)
    .toString()
    .padStart(2, '0')}:${(seconds % 60).toString().padStart(2, '0')}`;




  return (
    
      <div
        className={`bg-primary relative w-full rounded-lg p-6  ${
          language === "_ar" ? "text-right" : "text-left"
        }`}
      >
         {/* <div className="absolute z-50 top-0 left-0">
     <Texture className="object-cover"/>
      </div> */}
      <div className="space-y-6">
        <div className="space-y-2">
        <div className={`text-white ${
          language === "_ar" ? "text-right" : "text-left"
        } font-bold text-F22`}>
          {GetAppText("enter_otp")}
        </div>
        <div className={`text-white ${
          language === "_ar" ? "text-right" : "text-left"
        } font-semibold text-F14`}>
          {GetAppText("verify_code")}
        </div>
        </div>
        <div className=" w-full">
          <div className="flex justify-center  space-x-8">
         
          {inputValues.map((value, index) => (
        <div key={index} className="w-12">
          <InputComponent
            ref={(el) => (inputRefs.current[index] = el)}
            maxLength={1}
            input_style={"!text-center"}
            value={value}
            onChange={(e) => handleInputChange(index, e)}
            onKeyDown={(e) => handleKeyDown(index, e)}
          />
        </div>
      ))}
          </div>
          {alert&&<AlertComponent text={alert} svgName={"Warning"} svgColor={"#E94747"} styles={"!text-errorColor !bg-alertErrorBg !mt-5"}/>}
          <div className="pt-6 w-full"> 
            <div className="space-y-3 w-full ">
              <ButtonComponent disabled={loading}  loading={loading} styles={"!w-full !text-F20"} onClick={handler} title={GetAppText("sure")} />
         <div className="flex justify-center">
            <h1 className={`text-modelTextColor text-F14  w-fit text-center ${!resendOtp && 'cursor-pointer hover:underline'}`} onClick={otpResendHandler}>
          
              {resendOtp ? GetAppText("resend_after"): GetAppText("resend_code")}  {resendOtp && formattedTime}
  
            </h1>
            </div>
          
            </div>
          </div>
        </div>
        </div>
      </div>
    
  );
};

import React from "react";
import { GetAppText } from "../../utils";
import logo from "../../assets/images/footerLogo.png";
import facebook from "../../assets/svgs/facebook.svg";
import linkdin from "../../assets/svgs/linkdin.svg";
import tweeter from "../../assets/svgs/tweeter.svg";
import insta from "../../assets/svgs/instagram.svg";
import { ReactComponent as IOS } from "../../assets/svgs/apple.svg";
import { ReactComponent as Android } from "../../assets/svgs/android.svg";
import { ReactComponent as Gallery } from "../../assets/svgs/gallery.svg";
import { ReactComponent as Logo } from "../../assets/svgs/footerLogo.svg";
import { useSelector } from "react-redux";
import { SeparatorComponent } from "./Separator";
import { NavLink } from "react-router-dom";

function Footer() {
  const settingData = useSelector((state) => state?.setting?.value);

  const InstaInfo = settingData?.data?.find(
    item => item.name === 'INSTAGRAM',
  );

  const FacebookInfo = settingData?.data?.find(
    item => item.name === 'FACEBOOK',
  );

  const websiteInfo = settingData?.data?.find(
    item => item.name === 'WEBSITE',
  );

  const twitterInfo = settingData?.data?.find(
    item => item.name === 'TWITTER',
  );


  const IosInfo = settingData?.data?.find(
    item => item.name === 'IOS_STORE_LINK',
  );

  const androidInfo = settingData?.data?.find(
    item => item.name === 'ANDROID_STORE_LINK',
  );





  const img = [
    { id: 1, name: insta,tooltip:"Instagram",link:InstaInfo?.value },
    { id: 2, name: tweeter ,tooltip:"X",link:twitterInfo?.value},
    { id: 3, name: linkdin ,tooltip:"Linkdin",link:websiteInfo?.value},
    { id: 4, name: facebook,tooltip:"Facebook",link:FacebookInfo?.value },
  ];


  const language = useSelector((state) => state.language.value);

  const app = [
    { id: 1, name: "App Gallery", img: <Gallery />,link:androidInfo?.value },
    { id: 2, name: "Android", img: <Android /> ,link:androidInfo?.value},
    { id: 3, name: "IOS", img: <IOS /> , link :IosInfo?.value},
  ];

const handleClick=(item)=>{
  window.open(item?.link, "_blank")
}
const handleStore=(item)=>{
  window.open(item?.link, "_blank")
}
  return (
    <footer className="bg-primary shadow dark:bg-gray-900">
      <div className="w-full   p-4 lg:px-32 md:px-16 px-12  sm:pt-16 pt-10  md:pb-16">
        <div
          className={`sm:flex  ${
            language === "_ar" ? "flex-row-reverse" : ""
          }   sm:justify-between`}
        >
          <div className="sm:space-y-6 space-y-3">
          <NavLink to={"/"}>
            <a
             
              className={`flex  ${
                language === "_ar"
                  ? "flex-row-reverse space-x-3"
                  : " space-x-reverse"
              }  items-center  rtl:space-x-reverse`}
            >
              <Logo/>
            
            </a>
            </NavLink>
            <div className={`text-lightTextColor ${
                language === "_ar" ? "flex-row-reverse" : ""
              } flex  lg:text-F16 text-F13`}>
              {GetAppText("tagLine")}
            </div>
          </div>

          <div className="space-y-2.5 sm:pt-0 pt-5   flex-col md:block hidden items-end h-full ">
            <div
              className={`text-white flex  ${
                language === "_ar" ? "flex-row-reverse " : ""
              }  lg:text-F16 text-F14`}
            >
              {GetAppText("download_app")}
            </div>
            <div className={`flex  ${
                language === "_ar" ? "justify-end sm:justify-start" : ""
              } `}>
              <div className={`flex ${
                language === "_ar" ? "justify-end sm:justify-start" : ""
              }  flex-wrap gap-3`}>
                {app &&
                  app?.map((item) => (
                    <div
                    onClick={()=>{handleStore(item)}}
                      key={item?.id}
                      className="flex items-center cursor-pointer space-x-3 lg:text-F16 text-F13 text-white px-4 py-1.5 border-white rounded-md border-2 "
                    >
                      <div>{item.img}</div>
                      <div>{item.name}</div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className={`flex md:flex-row   ${
                language === "_ar" ? "items-end" : "items-start"
              } 
              flex-col-reverse md:justify-between  
        md:items-center md:space-y-0 space-y-6 space-y-reverse pt-7`}>
          <ul className={`flex  ${
                language === "_ar" ? "justify-end md:justify-start" : ""
              } sm:gap-8 gap-3 md:flex-wrap md:items-center w-full  
          lg:text-F16 text-F14 font-normal text-white md:mb-0 mb-2 dark:text-gray-400`}>
          <NavLink to={"/privacy-policy"}>
          <li>
              <a  className="hover:underline">
                {GetAppText("privacy_policy")}
              </a>
            </li>
            </NavLink>
            <NavLink to={"/terms-condition"}>
            <li>
              <a  className="hover:underline ">
                {GetAppText("terms_use")}
              </a>
            </li>
            </NavLink>
            <NavLink to={"/about-us"}>
            <li>
              <a  className="hover:underline ">
                {GetAppText("about_us")}
              </a>
            </li>
            </NavLink>
          </ul>
          <div className="space-y-2.5    flex-col md:hidden items-end h-full ">
            <div
              className={`text-white flex  ${
                language === "_ar" ? "flex-row-reverse " : ""
              }  lg:text-F16 text-F14`}
            >
              {GetAppText("download_app")}
            </div>
            <div className={`flex  ${
                language === "_ar" ? "justify-end sm:justify-start" : ""
              } `}>
              <div className={`flex ${
                language === "_ar" ? "justify-end sm:justify-start" : ""
              }  flex-wrap gap-3`}>
                {app &&
                  app?.map((item) => (
                    <div
                    onClick={()=>{handleStore(item)}}
                      key={item?.id}
                      className="flex items-center cursor-pointer space-x-3 lg:text-F16 text-F13 text-white px-4 py-1.5 border-white rounded-md border-2 "
                    >
                      <div>{item.img}</div>
                      <div>{item.name}</div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <ul
            className={`flex flex-wrap  sm:items-center   lg:text-F16 text-F14  ${
              language === "_ar" ? "flex-row-reverse space-x-reverse" : "md:justify-end"
            }   font-normal text-white sm:mb-0 w-full sm:gap-6 gap-3 dark:text-gray-400`}
          >
                  <NavLink to={"/"}>
            <li>
              <a  className="hover:underline  ">
                {GetAppText("home")}
              </a>
            </li>
            </NavLink>
            <NavLink to={"/donation-projects"}>
            <li>
              <a  className="hover:underline ">
                {GetAppText("donation")}
              </a>
            </li>
            </NavLink>
            <NavLink to={"/technical-support"}>
            <li>
              <a  className="hover:underline ">
                {GetAppText("technical_support")}
              </a>
            </li>
            </NavLink>
            <NavLink to={"/donars-bag"}>
            <li>
              <a  className="hover:underline ">
                {GetAppText("donation_basket")}
              </a>
            </li>
            </NavLink>
            {/* <NavLink to={"/profile"}>
            <li>
              <a  className="hover:underline ">
                {GetAppText("my_account")}
              </a>
            </li>
            </NavLink> */}
          </ul>
        </div>
     <SeparatorComponent />

        <div className={`flex md:flex-row flex-col flex-wrap ${
              language === "_ar" ? " items-end " : "flex-row-reverse items-start "
            } sm:gap-0 gap-4 md:justify-between md:items-center`}>
          <div className="flex lg:gap-6 gap-4">
            {img &&
              img?.map((item) => (
                <img
                title={item?.tooltip}
                  key={item?.id}
                  src={item.name}
                  className="lg:h-6 lg:w-6 h-5 w-5 cursor-pointer"
                  alt="Logo"
                  onClick={()=>{handleClick(item)}}
                />
              ))}
          </div>

          <div
            className={`sm:text-center  ${
              language === "_ar" ? "" : "flex-row-reverse space-x-reverse"
            } text-lightTextColor flex space-x-1 lg:text-F16 text-F13 dark:text-gray-400`}
          >
            <div>{GetAppText("all_rights")} </div>
            <span
              className={`flex ${language === "_ar" ? "" : "flex-row-reverse"}`}
            >
              <div>{new Date().getFullYear()}</div> <div>©</div>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

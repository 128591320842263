export const apiRoutes = {
get_home: 'home',
  get_projects:"projects",
  get_projectDetails:"projects/detail",
  get_news:"news",
  get_stories:"stories",
  get_storyDetails:"stories/detail",
  get_newsDetails:"news/detail",
  register:"register",
  login:"login",
  verify_otp:"login-confirm",
  logout:"logout",
  countries:"countries",
get_faqs:"faqs",
get_terms:"terms",
get_about:"about",
get_policy:"privacy",
get_cart:"carts",
add_cart:"carts/item",
delete_cart:"carts/remove",
add_cart_price:"cart/project/add/price",
get_init:"init",
get_sync_device:"device/web-sync",
update_profile:"user/update-profile",
get_cart_price:"cart/item",
add_donate:"donation/add",
get_donate:"donation",
delete_donate:"donation/item",
update_phone:'user/update-phone',
resend_otp:'login-resend',
delete_account:'user/delete-myaccount',
get_payment:'payment-methods',
get_global_search:'global-search',
get_mosques:'mosques',
get_mosques_details:'mosques/detail',
get_search:'global-search',
get_areas:'areas',
checkout:'checkout',
user_resend_otp:'user/update-phone-resend',
get_settings:'settings',
get_donation:'user/donations'
}